/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useMemo } from 'react';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type { AppProps, FormFieldEntry } from '@stimcar/libs-uitoolkit';
import type { MonitoredDevice } from '@stimcar/monitor-libs-common';
import { isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useGetState,
  useSelectorWithChangeTrigger,
} from '@stimcar/libs-uikernel';
import {
  FaIcon,
  ListSelect,
  ModalCardDialog,
  ScrollableTableComponent,
} from '@stimcar/libs-uitoolkit';
import { MonitorBackendRoutes } from '@stimcar/monitor-libs-common';
import type {
  DeviceDetailsState,
  Store,
  StoreState,
  UIMonitoredDevice,
} from './state/typings/store.js';
import { DeviceDetails } from './DeviceDetails.js';
import { selectDeviceAction } from './DeviceDetailsActions.js';
import { DeviceRow } from './DeviceRow.js';
import { EditDeviceDialog } from './EditDeviceDialog.js';

const NOT_SET_LOCATION = '<Not set>';

export function retrieveDeviceLocations(devices: readonly UIMonitoredDevice[]) {
  return devices
    .reduce<string[]>((p, { location }) => {
      return p.includes(location) ? p : [...p, location.trim()];
    }, [])
    .sort();
}

const onMoveSelectionUpOrDownHandler = async (
  { getState, actionDispatch }: ActionContext<Store, DeviceDetailsState>,
  event: React.KeyboardEvent<HTMLDivElement>,
  sortedDevices: readonly MonitoredDevice[]
): Promise<void> => {
  const { selectedDeviceShortId } = getState();
  if (selectedDeviceShortId) {
    if (event.key === 'Escape') {
      actionDispatch.setProperty('selectedDeviceShortId', undefined);
    } else {
      const idx = sortedDevices.map((k) => k.shortId).indexOf(selectedDeviceShortId);
      let newIdx = idx;
      switch (event.key) {
        case 'ArrowUp':
          newIdx -= 1;
          break;
        case 'ArrowDown':
          newIdx += 1;
          break;
        default:
      }
      if (newIdx < 0) {
        newIdx = 0;
      } else if (newIdx >= sortedDevices.length) {
        newIdx = sortedDevices.length - 1;
      }
      const newSelectedDevice = sortedDevices[newIdx];
      await actionDispatch.exec(selectDeviceAction, newSelectedDevice.shortId);
    }
  }
};

const ensureString = (str: string | undefined | null): string => (!str ? '' : str);

const compareWithEmptyStringsAtTheEnd = (
  str1: string | undefined | null,
  str2: string | undefined | null
): number => {
  if (isTruthyAndNotEmpty(str1) !== isTruthyAndNotEmpty(str2)) {
    return isTruthyAndNotEmpty(str1) ? -1 : 1;
  }
  return ensureString(str1).localeCompare(ensureString(str2));
};

export const removeDeviceAction = async (
  { httpClient, actionDispatch }: ActionContext<Store, StoreState>,
  deviceShortId: string
): Promise<void> => {
  actionDispatch.scopeProperty('confirmRemoveDeviceDialog').setProperty('active', false);
  await httpClient.httpGet(MonitorBackendRoutes.REMOVE_DEVICE(deviceShortId));
};

export function DeviceSelection({ $gs }: AppProps<Store>): JSX.Element {
  const confirmRemoveDeviceDialog = useGetState($gs.$confirmRemoveDeviceDialog);
  const devices = useGetState($gs.$devices);
  const locationFilter = useGetState($gs.$locationFilter);
  const selectedDeviceShortId = useGetState($gs.$deviceDetails.$selectedDeviceShortId);

  const sortedDevices = useMemo(() => {
    return devices
      .filter(({ location }) => locationFilter === location)
      .sort((d1, d2) => {
        // Empty location must appear at the end
        // Then locations must be sorted.
        const compare1 = compareWithEmptyStringsAtTheEnd(d1.location, d2.location);
        // For a same location, labels must be sorted
        return compare1 !== 0 ? compare1 : compareWithEmptyStringsAtTheEnd(d1.label, d2.label);
      });
  }, [devices, locationFilter]);

  const moveSelectionUpOrDownCallback = useActionCallback(
    async ({ actionDispatch }, event: React.KeyboardEvent<HTMLDivElement>): Promise<void> => {
      await actionDispatch.exec(onMoveSelectionUpOrDownHandler, event, sortedDevices);
    },
    [sortedDevices],
    $gs.$deviceDetails
  );

  const [deviceLocationToRemove, deviceLabelToRemove] = useMemo(() => {
    const { shortId } = confirmRemoveDeviceDialog;
    if (shortId) {
      const deviceToRemove = devices.find(({ shortId: cShortId }) => cShortId === shortId);
      if (deviceToRemove) {
        return [deviceToRemove.location, deviceToRemove.label];
      }
    }
    return [undefined, undefined];
  }, [confirmRemoveDeviceDialog, devices]);

  const removeDeviceActionCallback = useActionCallback(
    async ({ actionDispatch, getState }: ActionContext<Store, StoreState>) => {
      await actionDispatch.exec(removeDeviceAction, getState().confirmRemoveDeviceDialog.shortId);
    },
    [],
    $gs
  );

  const clearSelectionActionCallback = useActionCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async ({ actionDispatch }: ActionContext<Store, StoreState>) => {
      actionDispatch.scopeProperty('deviceDetails').setProperty('selectedDeviceShortId', '');
    },
    [],
    $gs
  );

  const locationEntries = useMemo(
    () =>
      retrieveDeviceLocations(devices).map(
        (location): FormFieldEntry<string> => ({
          id: location,
          label: location === '' ? NOT_SET_LOCATION : location,
        })
      ),
    [devices]
  );

  return (
    <>
      <div className="columns m-t-xs">
        <div className="column is-1">
          <ListSelect
            entries={locationEntries}
            $={useSelectorWithChangeTrigger($gs.$locationFilter, clearSelectionActionCallback)}
          />
        </div>
        <div className="column is-11">
          <div className="columns">
            <div
              className={`column is-${!selectedDeviceShortId ? 12 : 4}`}
              onKeyDown={moveSelectionUpOrDownCallback}
              tabIndex={0}
              role="menuitem"
            >
              <ScrollableTableComponent
                isNarrowUntilMaxHeight
                tableClassName="table is-bordered is-striped is-narrow is-hoverable is-fullwidth"
              >
                <thead>
                  <tr>
                    <th style={{ width: '1%' }}>C</th>
                    <th>Since</th>
                    <th>ID</th>
                    <th>Location</th>
                    <th style={{ width: '10%' }}>Label</th>
                    <th style={{ width: '10%' }}>Model</th>
                    {!selectedDeviceShortId && (
                      <>
                        <th>Public IP</th>
                        <th>LAN IP</th>
                        <th>System</th>
                        <th>V°</th>
                        <th>Arch</th>
                        <th>Node</th>
                        <th className="has-text-centered">
                          <FaIcon id="microchip" />
                        </th>
                        <th colSpan={2} className="has-text-centered">
                          <FaIcon id="memory" />
                        </th>
                        <th colSpan={2} className="has-text-centered">
                          <FaIcon id="hdd" />
                          root
                        </th>
                        <th colSpan={2} className="has-text-centered">
                          <FaIcon id="hdd" />
                          home
                        </th>
                        <th style={{ width: '1%' }}>&nbsp;</th>
                        <th style={{ width: '1%' }}>&nbsp;</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {sortedDevices.map((device) => (
                    <DeviceRow key={device.id} $gs={$gs} device={device} />
                  ))}
                </tbody>
              </ScrollableTableComponent>
            </div>
            {selectedDeviceShortId && (
              <div className="column is-8">
                <DeviceDetails
                  $gs={$gs}
                  device={nonnull(devices.find((d) => d.shortId === selectedDeviceShortId))}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <EditDeviceDialog $gs={$gs} />
      <ModalCardDialog
        $active={$gs.$confirmRemoveDeviceDialog.$active}
        title="Confirmation"
        okLabel="Ok"
        cancelLabel="Cancel"
        onOkClicked={removeDeviceActionCallback}
      >
        {`Are you sur you want to remove device '${deviceLocationToRemove}/${deviceLabelToRemove}' (${selectedDeviceShortId}) ?`}
      </ModalCardDialog>
    </>
  );
}
