import React from 'react';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { Logger } from '@stimcar/libs-kernel';
import { useActionCallback } from '@stimcar/libs-uikernel';
import { ClickableIcon, LoadingObject } from '@stimcar/libs-uitoolkit';
import { MonitorBackendRoutes } from '@stimcar/monitor-libs-common';
import type {
  DeviceDetailsState,
  Store,
  StoreState,
  UIMonitoredDevice,
} from './state/typings/store.js';
import {
  openRemoveDeviceDialogAction,
  openScreenshotAction,
  selectDeviceAction,
} from './DeviceDetailsActions.js';
import { DeviceLogo } from './DeviceLogo.js';
import { approveDeviceAction } from './DeviceRow.js';
import { openEditDialogAction } from './EditDeviceDialog.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

export interface DeviceDetailsHeaderProps extends AppProps<Store> {
  readonly device: UIMonitoredDevice;
}

export function DeviceDetailsHeader({ $gs, device }: DeviceDetailsHeaderProps): JSX.Element {
  const { $deviceDetails } = $gs;
  const openEditDialogActionCallback = useActionCallback(
    async ({ actionDispatch }: ActionContext<Store, StoreState>): Promise<void> => {
      await actionDispatch.scopeProperty('editDeviceDialog').exec(openEditDialogAction, device);
    },
    [device],
    $gs
  );
  const openConfirmRemoveDeviceDialogActionCallback = useActionCallback(
    async ({ actionDispatch }: ActionContext<Store, StoreState>): Promise<void> => {
      await actionDispatch.exec(openRemoveDeviceDialogAction, device);
    },
    [device],
    $gs
  );
  const approveDeviceActionCallback = useActionCallback(
    async ({ actionDispatch }: ActionContext<Store, StoreState>): Promise<void> => {
      await actionDispatch.exec(approveDeviceAction, device.shortId);
    },
    [device.shortId],
    $gs
  );
  const unselectDeviceActionCallback = useActionCallback(
    async ({ actionDispatch }: ActionContext<Store, DeviceDetailsState>): Promise<void> => {
      await actionDispatch.exec(selectDeviceAction, undefined);
    },
    [],
    $deviceDetails
  );

  const openScreenshotActionCallback = useActionCallback(openScreenshotAction, [], $gs);

  return (
    <>
      <div style={{ float: 'right' }}>
        <button
          className="delete m-b-xl"
          type="button"
          aria-label="close"
          onClick={unselectDeviceActionCallback}
        />
      </div>
      <div className="columns is-vcentered">
        <div className="column is-narrow">
          <DeviceLogo device={device} size={110} />
        </div>
        <div className="column">
          <div className="level">
            <div className="level-left">
              <div>
                <p className="title is-4">
                  {`${device.location} ${!device.label ? device.id : device.label}`}
                  <ClickableIcon
                    clickHandler={
                      device.status === 'confirmed'
                        ? openEditDialogActionCallback
                        : approveDeviceActionCallback
                    }
                    id={device.status === 'confirmed' ? 'edit' : 'check-square'}
                    elementClassName="m-l-sm"
                  />
                  <ClickableIcon
                    clickHandler={openConfirmRemoveDeviceDialogActionCallback}
                    id="trash"
                    elementClassName="m-l-sm"
                  />
                </p>
                <p className="subtitle is-5">
                  {[device.model, device.system, device.systemRelease, device.systemArch].join(
                    ', '
                  )}
                  <br />
                  {[
                    device.systemNode,
                    `IP EXT: ${device.publicIp}`,
                    `IP LAN: ${device.lanIp}`,
                  ].join(', ')}
                  <br />
                  {`#${device.shortId}, @${device.id}`}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-narrow">
          <LoadingObject
            errorIcon="desktop"
            height={110}
            transparentBackground={false}
            onClickCallback={openScreenshotActionCallback}
            src={`${MonitorBackendRoutes.DEVICE_SCREENSHOT(device.shortId)}${
              device.screenshotUpdateTime ? `?ts=${device.screenshotUpdateTime}` : ''
            }`}
          />
        </div>
      </div>
    </>
  );
}
