import { WAN_API_ROUTE_PREFIX } from '@stimcar/libs-kernel';

export const MonitorBackendRoutes = {
  // Agent routes
  REGISTER_DEVICE: (deviceId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/device/${deviceId}/register`,
  ASSERT_DEVICE_ALIVE: (deviceId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/device/${deviceId}/alive`,
  DEVICE_AGENT_PULL_COMMAND: (deviceId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/device/${deviceId}/agent/pullcommand`,
  SET_DEVICE_COMMAND_PID: (deviceId: string, commandId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/device/${deviceId}/command/${commandId}/setpid`,
  SET_DEVICE_COMMAND_COMPLETED: (deviceId: string, commandId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/device/${deviceId}/command/${commandId}/completed`,
  APPEND_DEVICE_COMMAND_LOG: (deviceId: string, commandId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/device/${deviceId}/command/${commandId}/log/append`,
  UPDATE_DEVICE_SCREENSHOT: (deviceId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/device/${deviceId}/screenshot/update`,
  // UI routes
  DEVICE_LIST: `${WAN_API_ROUTE_PREFIX}/device/list`,
  APPROVE_DEVICE: (deviceShortId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/device/${deviceShortId}/approve`,
  REMOVE_DEVICE: (deviceShortId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/device/${deviceShortId}/remove`,
  UPDATE_DEVICE: (deviceShortId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/device/${deviceShortId}/update`,
  NEW_DEVICE_COMMAND: (deviceShortId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/device/${deviceShortId}/command/new`,
  DEVICE_COMMAND_LIST: (deviceShortId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/device/${deviceShortId}/command/list`,
  DEVICE_SCREENSHOT: (deviceShortId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/device/${deviceShortId}/screenshot`,
  DEVICE_COMMAND_LOG_LIST: (deviceShortId: string, commandId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/device/${deviceShortId}/command/${commandId}/log/list`,
  SSE: `${WAN_API_ROUTE_PREFIX}/sse`,
};

export const MONITOR_BUS_EVENT_SSE_MESSAGE = 'MonitorBusEvent';
