import type { JSX } from 'react';
import React, { useMemo } from 'react';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import type { MonitoredDevice } from '@stimcar/monitor-libs-common';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import {
  InputFormField,
  ModalCardDialog,
  ReactSelectFormField,
  useFormWithValidation,
} from '@stimcar/libs-uitoolkit';
import { MonitorBackendRoutes } from '@stimcar/monitor-libs-common';
import type {
  DeviceForm,
  DeviceFormData,
  EditDeviceDialogState,
  Store,
} from './state/typings/store.js';
import { EMPTY_EDIT_DEVICE_DIALOG_STATE } from './state/typings/store.js';

function convertToFormData({ label, location }: MonitoredDevice): DeviceForm {
  return {
    label,
    location,
    warnings: {},
  };
}

// eslint-disable-next-line @typescript-eslint/require-await
export async function openEditDialogAction(
  { actionDispatch }: ActionContext<Store, EditDeviceDialogState>,
  initialDevice: MonitoredDevice
): Promise<void> {
  actionDispatch.reduce(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (initial: EditDeviceDialogState): EditDeviceDialogState => {
      return {
        ...EMPTY_EDIT_DEVICE_DIALOG_STATE,
        active: true,
        initialDevice,
        formData: convertToFormData(initialDevice),
      };
    }
  );
}

// eslint-disable-next-line @typescript-eslint/require-await
async function closeEditDialogAction({
  actionDispatch,
}: ActionContext<Store, EditDeviceDialogState>): Promise<void> {
  actionDispatch.setProperty('active', false);
}

async function saveDevice({
  getState,
  actionDispatch,
  httpClient,
}: ActionContext<Store, EditDeviceDialogState>): Promise<void> {
  const { formData, initialDevice } = getState();
  const { label, location } = formData;

  // Update data
  await httpClient.httpPostAsJSON(MonitorBackendRoutes.UPDATE_DEVICE(initialDevice.shortId), {
    label,
    location,
  });

  // Close the diagram
  await actionDispatch.exec(closeEditDialogAction);
}

const MANDATORY_FIELDS: (keyof DeviceFormData)[] = ['label'];

// FIXME fake TFunction
const tFunction = (code: string): string => code;

export function EditDeviceDialog({ $gs }: AppProps<Store>): JSX.Element {
  const $ = $gs.$editDeviceDialog;

  const submitValidDataAction = useActionCallback(saveDevice, [], $);

  const devices = useGetState($gs.$devices);

  const locationSuggestions = useMemo((): readonly string[] => {
    return devices.reduce<readonly string[]>(
      (p, { location }) =>
        p.includes(location) || location.trim().length === 0 ? p : [...p, location],
      []
    );
  }, [devices]);

  const [onFormSubmit, , $formWithChangeTrigger] = useFormWithValidation<
    Store,
    EditDeviceDialogState
  >({
    $,
    mandatoryFields: MANDATORY_FIELDS,
    submitValidDataAction,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    t: tFunction as any,
  });

  const formWarning = useGetState($.$formWarning);

  return (
    <ModalCardDialog
      title="Edit device's label"
      $active={$.$active}
      okLabel="Ok"
      cancelLabel="Cancel"
      onOkClicked={onFormSubmit}
      warning={formWarning}
    >
      <div className="columns">
        <div className="column">
          <ReactSelectFormField
            label="Location"
            $={$formWithChangeTrigger.$location}
            suggestions={locationSuggestions}
            creation
            horizontal
          />
          <InputFormField label="Label" horizontal $={$formWithChangeTrigger.$label} />
        </div>
      </div>
    </ModalCardDialog>
  );
}
