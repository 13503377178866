import type { DeepPartial } from '@stimcar/libs-kernel';

export type MonitorEventType<T extends string, P> = {
  readonly type: T;
  readonly payload: P;
};

export type MonitorEvent =
  | MonitorEventType<'newDevice', MonitoredDevice>
  | MonitorEventType<'removeDevice', string>
  | MonitorEventType<'updateDevice', DeepPartial<MonitoredDevice>>
  | MonitorEventType<'newCommand', MonitoredDeviceCommand>
  | MonitorEventType<'updateCommand', DeepPartial<MonitoredDeviceCommand>>
  | MonitorEventType<'newCommandLog', MonitoredDeviceCommandLog>
  | MonitorEventType<'newDeviceScreenshot', string /* device id */>;

export type MonitoredDevice = {
  readonly id: string;
  readonly shortId: string;
  readonly agentIsEnabled: boolean;
  readonly label: string;
  readonly location: string;
  readonly lastConnection: number;
  readonly publicIp: string;
  readonly lanIp: string;
  readonly model: string;
  readonly status: 'unconfirmed' | 'confirmed';
  readonly system: string;
  readonly systemRelease: string;
  readonly systemArch: string;
  readonly systemNode: string;
  readonly systemLoad: number;
  readonly rootDiskSpace: number;
  readonly rootDiskUsage: number;
  readonly homeDiskSpace: number;
  readonly homeDiskUsage: number;
  readonly memory: number;
  readonly memoryUsage: number;
};

export const EMPTY_MONITORED_DEVICE: MonitoredDevice = {
  id: '',
  shortId: '',
  agentIsEnabled: false,
  label: '',
  location: '',
  lastConnection: 0,
  publicIp: '',
  lanIp: '',
  model: '',
  status: 'unconfirmed',
  system: '',
  systemRelease: '',
  systemArch: '',
  systemNode: '',
  rootDiskSpace: 0,
  rootDiskUsage: 0,
  homeDiskSpace: 0,
  homeDiskUsage: 0,
  memory: 0,
  memoryUsage: 0,
  systemLoad: 0,
};

export type MonitoredDeviceCommand = {
  readonly deviceId: string;
  readonly id: string;
  readonly type: 'vnctunnel' | 'sshtunnel' | 'customcommand' | 'kill' | 'reboot' | 'screenshot';
  readonly arguments: string;
  readonly acknowledged: boolean;
  readonly pid: number;
  readonly completed: boolean;
  readonly timestamp: number;
  readonly exitCode: number;
};

export const EMPTY_MONITORED_DEVICE_COMMAND: MonitoredDeviceCommand = {
  deviceId: '',
  id: '0',
  type: 'customcommand',
  arguments: '',
  acknowledged: false,
  pid: 0,
  completed: false,
  timestamp: 0,
  exitCode: 0,
};

export type MonitoredDeviceCommandLog = {
  readonly deviceId: string;
  readonly commandId: string;
  readonly id: string;
  readonly orderIndex: number;
  readonly data: string;
  readonly timestamp: number;
};

export const EMPTY_MONITORED_DEVICE_COMMAND_LOG: MonitoredDeviceCommandLog = {
  deviceId: '',
  commandId: '0',
  id: '0',
  orderIndex: 0,
  data: '',
  timestamp: 0,
};
