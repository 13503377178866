import type { KeyValueStorage } from '@stimcar/libs-kernel';
import type { ActionContext, GlobalDispatch } from '@stimcar/libs-uikernel';
import type { AppEnvironment } from '@stimcar/libs-uitoolkit';
import type { MonitoredDevice, MonitorEvent } from '@stimcar/monitor-libs-common';
import { ensureError, getHttpStatusCode, Logger, nonnull } from '@stimcar/libs-kernel';
import { BrowserKeyValueStorageImpl } from '@stimcar/libs-uikernel';
import { launchApp } from '@stimcar/libs-uitoolkit';
import { MONITOR_BUS_EVENT_SSE_MESSAGE, MonitorBackendRoutes } from '@stimcar/monitor-libs-common';
import type {
  Store,
  StoreActionContext,
  StoreState,
  UIMonitoredDevice,
} from './app/state/typings/store.js';
import { App } from './app/App.js';
import {
  extractTunnelPortFromLog,
  injectNewCommandIntoStateAction,
  isTunneledCommand,
  selectDeviceAction,
} from './app/DeviceDetailsActions.js';
import { retrieveDeviceLocations } from './app/DeviceSelection.js';
import { EMPTY_STORE_STATE } from './app/state/store.js';
import { MonitorHttpClientImpl } from './app/utils/MonitorHttpClientImpl.js';
import './sass/styles';
import { HttpErrorCodes } from '@stimcar/libs-base';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

interface MonitorEnv extends AppEnvironment {
  readonly httpClient: MonitorHttpClientImpl;
  readonly keyValueStorage: KeyValueStorage;
}

async function loadDevicesAction({
  httpClient,
  actionDispatch,
}: ActionContext<Store, StoreState>): Promise<void> {
  const devices = await httpClient.httpGetAsJson<readonly MonitoredDevice[]>(
    MonitorBackendRoutes.DEVICE_LIST
  );
  actionDispatch.setProperty('devices', devices);
}

function bindEnvironmentToStore({ httpClient }: MonitorEnv, dispatch: GlobalDispatch<Store>) {
  httpClient.registerServerMessageListener(
    MONITOR_BUS_EVENT_SSE_MESSAGE,
    async (data: unknown): Promise<void> => {
      const event = data as MonitorEvent;
      log.debug('Monitor Bus Event:', data);
      switch (event.type) {
        case 'newDevice':
          // Reload devices
          await dispatch.exec(loadDevicesAction);
          break;
        case 'removeDevice':
          await dispatch.exec(
            async ({
              getState,
              actionDispatch,
            }: // eslint-disable-next-line @typescript-eslint/require-await
            ActionContext<Store, StoreState>): Promise<void> => {
              const id = event.payload;
              const { devices, deviceDetails } = getState();
              const removedDevice = devices.find(({ id: cId }) => cId === id);
              // If the removed device was selected, unselect it
              if (removedDevice && deviceDetails.selectedDeviceShortId === removedDevice.shortId) {
                actionDispatch
                  .scopeProperty('deviceDetails')
                  .setProperty('selectedDeviceShortId', undefined);
              }
              // Remove device from list
              const newDevicesList = devices.filter(({ id: cId }) => cId !== id);
              actionDispatch.setProperty('devices', newDevicesList);
            }
          );
          break;
        case 'updateDevice':
          await dispatch.exec(
            async ({
              getState,
              actionDispatch,
            }: // eslint-disable-next-line @typescript-eslint/require-await
            ActionContext<Store, StoreState>): Promise<void> => {
              const { id: updatedDeviceId } = event.payload;
              const { devices } = getState();
              actionDispatch.setProperty(
                'devices',
                devices.map((device) => {
                  return updatedDeviceId !== device.id
                    ? device
                    : {
                        ...device,
                        ...event.payload,
                      };
                })
              );
            }
          );
          break;
        case 'newCommand':
          await dispatch.exec(
            async ({
              getState,
              actionDispatch,
            }: // eslint-disable-next-line @typescript-eslint/require-await
            ActionContext<Store, StoreState>): Promise<void> => {
              const newCommand = event.payload;
              const { deviceDetails, devices } = getState();
              const { selectedDeviceShortId, commands } = deviceDetails;
              // Find device
              const device = devices.find((d) => d.shortId === selectedDeviceShortId);
              if (device && device.id === newCommand.deviceId) {
                if (!commands.map((c) => c.id).includes(newCommand.id)) {
                  await actionDispatch
                    .scopeProperty('deviceDetails')
                    .exec(injectNewCommandIntoStateAction, device.shortId, newCommand);
                }
              }
            }
          );
          break;
        case 'updateCommand':
          await dispatch.exec(
            async ({
              getState,
              actionDispatch,
            }: // eslint-disable-next-line @typescript-eslint/require-await
            ActionContext<Store, StoreState>): Promise<void> => {
              const commandId = nonnull(event.payload.id);
              const deviceId = nonnull(event.payload.deviceId);
              const { deviceDetails, devices } = getState();
              const { selectedDeviceShortId, commands } = deviceDetails;
              // Find device
              const device = devices.find((d) => d.shortId === selectedDeviceShortId);
              if (device && device.id === deviceId) {
                if (commands.map((c) => c.id).includes(commandId)) {
                  actionDispatch.scopeProperty('deviceDetails').setProperty(
                    'commands',
                    commands.map((c) => {
                      if (c.id !== commandId) {
                        return c;
                      }
                      return {
                        ...c,
                        ...event.payload,
                      };
                    })
                  );
                }
              }
            }
          );
          break;
        case 'newCommandLog':
          await dispatch.exec(
            async ({
              getState,
              actionDispatch,
            }: // eslint-disable-next-line @typescript-eslint/require-await
            ActionContext<Store, StoreState>): Promise<void> => {
              const commandId = nonnull(event.payload.commandId);
              const deviceId = nonnull(event.payload.deviceId);
              const { deviceDetails, devices } = getState();
              const { selectedDeviceShortId, commands } = deviceDetails;
              // Find device
              const device = devices.find((d) => d.shortId === selectedDeviceShortId);
              if (device && device.id === deviceId) {
                const command = commands.find((c) => c.id === commandId);
                if (command && !command.logs.map((l) => l.id).includes(event.payload.id)) {
                  actionDispatch.scopeProperty('deviceDetails').setProperty(
                    'commands',
                    commands.map((c) => {
                      if (c.id !== commandId) {
                        return c;
                      }
                      let { tunnelPort } = c;
                      if (!tunnelPort) {
                        tunnelPort = isTunneledCommand(c)
                          ? extractTunnelPortFromLog(event.payload.data)
                          : undefined;
                        // Hook
                      }
                      return {
                        ...c,
                        tunnelPort,
                        logs: [...c.logs, event.payload],
                      };
                    })
                  );
                }
              }
            }
          );
          break;
        case 'newDeviceScreenshot':
          await dispatch.exec(
            async ({
              getState,
              actionDispatch,
            }: // eslint-disable-next-line @typescript-eslint/require-await
            ActionContext<Store, StoreState>): Promise<void> => {
              const deviceId = event.payload;
              const { devices } = getState();
              // Find device
              const device = devices.find((d) => d.id === deviceId);
              if (device) {
                actionDispatch.setProperty(
                  'devices',
                  devices.map((d) => {
                    if (d.id !== deviceId) {
                      return d;
                    }
                    return {
                      ...d,
                      // Will force a reload of the screenshot
                      screenshotUpdateTime: Date.now(),
                    };
                  })
                );
              }
            }
          );
          break;
        default:
        // Do nothing
      }
    }
  );
}

function createStoreActionContext({
  httpClient,
  keyValueStorage,
}: MonitorEnv): Partial<StoreActionContext> {
  return {
    httpClient,
    keyValueStorage,
  };
}

const onStoreReadyAction = async ({
  actionDispatch,
  getState,
  runWithProgressBar,
}: ActionContext<Store, StoreState>): Promise<void> => {
  await runWithProgressBar(10, async () => {
    try {
      // Load devices
      await actionDispatch.exec(loadDevicesAction);
      const { search } = document.location;
      const { devices } = getState();
      if (search) {
        // If a selector is specified in the URL, select it :
        const params = new URLSearchParams(document.location.search);
        const idToSelect = params.get('id');
        const shortIdToSelect = params.get('shortId');
        const labelToSelect = params.get('label');
        const locationToSelect = params.get('location');
        let selectedDevice: UIMonitoredDevice | undefined;
        // Select by id...
        if (idToSelect) {
          selectedDevice = devices.find(({ id }) => id === idToSelect);
        }
        // Or select by short id...
        else if (shortIdToSelect) {
          selectedDevice = devices.find(({ shortId }) => shortId === shortIdToSelect);
        }
        // Or select by label and location if specified...
        else if (labelToSelect && locationToSelect) {
          selectedDevice = devices.find(
            ({ label, location }) => label === labelToSelect && locationToSelect === location
          );
        }
        // If a device is selected, commit the selection
        if (selectedDevice) {
          await actionDispatch
            .scopeProperty('deviceDetails')
            .exec(selectDeviceAction, selectedDevice.shortId, false);
          actionDispatch.setProperty('locationFilter', selectedDevice.location);
        }
      } else {
        // Collect devices locations
        const locations = retrieveDeviceLocations(devices);
        if (locations.length > 0) {
          actionDispatch.setProperty('locationFilter', locations[0]);
        }
      }
    } catch (e) {
      const error = ensureError(e);
      // If unauthorized, show an popup message
      if (getHttpStatusCode(error) === HttpErrorCodes.UNAUTHORIZED) {
        actionDispatch.setProperty('message', `Not authorized : ${error.message}`);
      }
      throw error;
    }
  });
};
// Launch the application
launchApp<Store, MonitorEnv>({
  appComponent: App,
  initialStoreState: EMPTY_STORE_STATE,
  environment: {
    httpClient: new MonitorHttpClientImpl(),
    keyValueStorage: new BrowserKeyValueStorageImpl(),
  },
  bindEnvironmentToStore,
  createStoreActionContext,
  onStoreReadyAction,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  t: ((...args: any) => String(args)) as any,
});
