import type { JSX } from 'react';
import React, { useCallback, useMemo } from 'react';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import type { MonitoredDevice } from '@stimcar/monitor-libs-common';
import { isTruthyAndNotEmpty, Logger } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState, useSetCallback } from '@stimcar/libs-uikernel';
import {
  Button,
  InputFormField,
  ModalCardDialog,
  ScrollableContainerWithHeaderAndFooter,
} from '@stimcar/libs-uitoolkit';
import type { DeviceDetailsState, Store } from './state/typings/store.js';
import { DeviceCommand } from './DeviceCommand.js';
import { newCommandAction } from './DeviceDetailsActions.js';
import { DeviceDetailsFooter } from './DeviceDetailsFooter.js';
import { DeviceDetailsHeader } from './DeviceDetailsHeader.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

const onKeyPressedHandler = async (
  { actionDispatch }: ActionContext<Store, DeviceDetailsState>,
  event: React.KeyboardEvent<HTMLDivElement>
  // eslint-disable-next-line @typescript-eslint/require-await
): Promise<void> => {
  if (event.key === 'Escape') {
    actionDispatch.setProperty('selectedDeviceShortId', undefined);
  }
};

const newCustomCommand = async ({
  getState,
  actionDispatch,
}: ActionContext<Store, DeviceDetailsState>): Promise<void> => {
  const { commandForm } = getState();
  const { value } = commandForm;
  await actionDispatch.exec(newCommandAction, 'customcommand', value);
};

const newRebootCommand = async ({
  actionDispatch,
}: ActionContext<Store, DeviceDetailsState>): Promise<void> => {
  await actionDispatch.exec(newCommandAction, 'reboot', '');
  // Close confirm dialog
  actionDispatch.setProperty('confirmRebootModalDialogIsActive', false);
};

const newScreenshotCommand = async ({
  actionDispatch,
}: ActionContext<Store, DeviceDetailsState>): Promise<void> => {
  await actionDispatch.exec(newCommandAction, 'screenshot', '', true);
};

const startSshTunnelAndOpenViewer = async ({
  actionDispatch,
}: ActionContext<Store, DeviceDetailsState>): Promise<void> => {
  await actionDispatch.exec(newCommandAction, 'sshtunnel', '', true);
};

const startVncTunnelAndOpenViewer = async ({
  actionDispatch,
}: ActionContext<Store, DeviceDetailsState>): Promise<void> => {
  const password = Math.floor(1000000000000 * Math.random())
    .toString(32)
    .padStart(9, '0');
  await actionDispatch.exec(newCommandAction, 'vnctunnel', password, true);
};

export interface DeviceDetailsProps extends AppProps<Store> {
  readonly device: MonitoredDevice;
}

export function DeviceDetails({ $gs, device }: DeviceDetailsProps): JSX.Element {
  const $ = $gs.$deviceDetails;
  const commands = useGetState($.$commands);
  const formValue = useGetState($.$commandForm.$value);

  const isExecuteCommandButtonDisabled = useMemo(
    (): boolean => !isTruthyAndNotEmpty(formValue),
    [formValue]
  );

  const sortedCommands = useMemo(() => {
    return [...commands].sort((c1, c2) => String(c2.id).localeCompare(c1.id));
  }, [commands]);

  const newCommandActionCallback = useActionCallback(newCustomCommand, [], $);

  const newRebootCommandActionCallback = useActionCallback(newRebootCommand, [], $);

  const newScreenshotCommandCallback = useActionCallback(newScreenshotCommand, [], $);

  const openSshTunnelCallback = useActionCallback(startSshTunnelAndOpenViewer, [], $);

  const openVncConsoleCallback = useActionCallback(startVncTunnelAndOpenViewer, [], $);

  const onKeyPressedHandlerCallback = useActionCallback(onKeyPressedHandler, [], $);

  const executeCommandOnEnterKeyPressed = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
      if (e.key === 'Enter' || e.keyCode === 13) {
        await newCommandActionCallback();
      }
    },
    [newCommandActionCallback]
  );

  const rebootButtonIsDisabled = useMemo(() => {
    const allCommandsCompleted = commands.reduce<boolean>((p, c) => p && c.completed, true);
    return !allCommandsCompleted;
  }, [commands]);

  const setConfirmRebootModalDialogIsActiveCallback = useSetCallback(
    $.$confirmRebootModalDialogIsActive,
    true
  );
  return (
    <div className="box" onKeyDown={onKeyPressedHandlerCallback} tabIndex={0} role="menuitem">
      <ScrollableContainerWithHeaderAndFooter
        headerComponent={<DeviceDetailsHeader $gs={$gs} device={device} />}
        footerComponent={<DeviceDetailsFooter device={device} />}
      >
        <div className="content">
          {device.status === 'confirmed' && device.agentIsEnabled && (
            <>
              <div className="columns">
                <div className="column">
                  <InputFormField
                    label="Run:"
                    horizontal
                    $={$.$commandForm.$value}
                    className="is-fullwidth"
                    onKeyPress={executeCommandOnEnterKeyPressed}
                  />
                </div>
                <div className="column is-narrow p-l-none">
                  <Button
                    iconId="play"
                    onClick={newCommandActionCallback}
                    size="small"
                    disabled={isExecuteCommandButtonDisabled}
                    additionalClass="has-background-grey-lighter has-text-success"
                  />
                </div>
                <div className="column is-narrow p-l-none">
                  <Button
                    iconId="terminal"
                    onClick={openSshTunnelCallback}
                    size="small"
                    tooltip="SSH tunnel"
                    additionalClass="is-dark"
                  />
                </div>
                <div className="column is-narrow p-l-none">
                  <Button
                    iconId="mouse-pointer"
                    onClick={openVncConsoleCallback}
                    size="small"
                    tooltip="VNC tunnel"
                    additionalClass="is-primary"
                  />
                </div>
                <div className="column is-narrow p-l-none">
                  <Button
                    iconId="desktop"
                    onClick={newScreenshotCommandCallback}
                    size="small"
                    tooltip="Take a screenshot"
                    additionalClass="is-warning"
                  />
                </div>
                <div className="column is-narrow p-l-none">
                  <Button
                    iconId="power-off"
                    onClick={setConfirmRebootModalDialogIsActiveCallback}
                    size="small"
                    tooltip="Reboot"
                    disabled={rebootButtonIsDisabled}
                    additionalClass="is-danger"
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  {sortedCommands.map((c) => (
                    <DeviceCommand key={c.id} $gs={$gs} command={c} />
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </ScrollableContainerWithHeaderAndFooter>
      <ModalCardDialog
        $active={$.$confirmRebootModalDialogIsActive}
        title="Confirmation"
        okLabel="Ok"
        cancelLabel="Cancel"
        onOkClicked={newRebootCommandActionCallback}
      >
        {`Are you sur you want to reboot ${device.label} ?`}
      </ModalCardDialog>
    </div>
  );
}
