import React from 'react';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import type { Store } from './state/typings/store.js';
import { DeviceSelection } from './DeviceSelection.js';
import { NavBar } from './NavBar.js';

export function App({ $gs }: AppProps<Store>): JSX.Element {
  return (
    <>
      <NavBar $gs={$gs} />
      <div className="container is-fluid">
        <DeviceSelection $gs={$gs} />
      </div>
    </>
  );
}
