import React from 'react';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { LightDarkThemeSelector } from '@stimcar/libs-uikernel';
import type { Store } from './state/typings/store.js';

export function NavBar({ $gs }: AppProps<Store>): JSX.Element {
  /* eslint-disable jsx-a11y/interactive-supports-focus */
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/anchor-is-valid */
  return (
    <>
      <nav
        className="navbar is-primary is-fixed-top"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <a className="navbar-item is-active active" href="/" aria-current="page">
            <img src="img/logo.png" alt="stimcar" />
          </a>
          <a
            role="button"
            className="navbar-burger burger is-active"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
            // eslint-disable-next-line no-alert
            onClick={() => window.alert('not implemented')}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>
        <div id="mainNavbar" className="navbar-menu is-active">
          <div className="navbar-start">{/* TODO */}</div>
          <div className="navbar-end">
            <div className="navbar-item">
              <LightDarkThemeSelector $gs={$gs} />
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
