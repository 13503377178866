import type { JSX } from 'react';
import React from 'react';
import { Logger } from '@stimcar/libs-kernel';
import { FaIcon } from '@stimcar/libs-uitoolkit';
import type { UIMonitoredDevice } from './state/typings/store.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

export interface DeviceDetailsFooterProps {
  readonly device: UIMonitoredDevice;
}

export function DeviceDetailsFooter({ device }: DeviceDetailsFooterProps): JSX.Element {
  const {
    status,
    systemLoad,
    memory,
    memoryUsage,
    rootDiskSpace,
    rootDiskUsage,
    homeDiskSpace,
    homeDiskUsage,
  } = device;
  return (
    <>
      {status === 'confirmed' && (
        <div className="columns p-t-md">
          <div className="column">
            <FaIcon id="microchip" />
            {`Load: ${systemLoad}`}
          </div>
          <div className="column">
            {memory !== 0 && (
              <>
                <FaIcon id="memory" />
                {`: ${(memory / 1024).toFixed(0)}M (${((memoryUsage / memory) * 100).toFixed(1)}%)`}
              </>
            )}
          </div>
          <div className="column">
            {rootDiskSpace !== 0 && (
              <>
                <FaIcon id="hdd" />
                {`/: ${(rootDiskSpace / 1024).toFixed(0)}M (${(
                  (rootDiskUsage / rootDiskSpace) *
                  100
                ).toFixed(1)}%)`}
              </>
            )}
          </div>
          <div className="column">
            {homeDiskSpace !== 0 && (
              <>
                <FaIcon id="hdd" />
                {`/home: ${(homeDiskSpace / 1024).toFixed(0)}M (${(
                  (homeDiskUsage / homeDiskSpace) *
                  100
                ).toFixed(1)}%)`}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}
