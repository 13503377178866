import type { Entity } from '@stimcar/libs-kernel';
import { isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import type { EntryPointId, EntryPointInfo } from '../model/coreConstants.js';
import { ENTRYPOINT_IDS } from '../model/coreConstants.js';

/**
 * Extracts the subdomain of a given hostname.
 * The given hostname is expected to contain at least two dots, ex: subdomain.refitngin.io
 */
export function extractSubdomain(hostname: string): string {
  if (/^[0-9.]*$/.test(hostname)) {
    // Otherwise, it's invalid
    throw new Error(`Invalid hostname : ${hostname}, IP not accepted`);
  }
  const fragments = hostname.split('.');
  if (fragments.length <= 2) {
    throw new Error(`Invalid hostname : ${hostname}, company subdomain not found`);
  }
  fragments.pop();
  fragments.pop();
  return nonnull(fragments.pop());
}

export function trim(data: string | undefined | null): string {
  return isTruthyAndNotEmpty(data) ? data.trim() : '';
}

/**
 * In development environment, the subDomain can be forced through an environment variable.
 * Otherwise, the subDomain comes from the hostname
 */
function getSubdomain(hostname: string): string {
  if (process.env.NODE_ENV !== 'production' && process.env.SUBDOMAIN !== undefined) {
    return process.env.SUBDOMAIN;
  }
  return extractSubdomain(hostname);
}

/**
 * Retrieves the application entry point in the given hostname.
 *
 * The hostname is expected to respect the following pattern : <companyId>-<entryPointId>
 *
 * @param hostname the hostname to parse.
 * @returns the entry point info.
 */
export function retrieveEntryPointInfo(hostname: string): EntryPointInfo {
  const subDomain = getSubdomain(hostname);
  const fragments = subDomain.split('-');
  const companyId = fragments[0];
  const entryPointId = (fragments[1] ?? 'main') as EntryPointId;
  if (!ENTRYPOINT_IDS.includes(entryPointId)) {
    throw new Error(
      `Unexpected entry point '${entryPointId}' (known entries: ${ENTRYPOINT_IDS.join(', ')})`
    );
  }
  return { companyId, entryPointId };
}

/**
 * Returns the index at the start of the extension in filename
 * which is the position of the last dot in filename
 * except if this corresponds to the very first character of filename
 * - for 'abce.fgh' => returns 4
 * - for 'abc.def.ghi' => returns 7
 * - for '.abcde' => returns -1 because dot is at the beginning of string
 */
function getExtensionStartingIndex(filename: string): number {
  const index = filename.lastIndexOf('.');
  return index > 0 ? index : -1;
}

/**
 * Returns the extension from a file's name i.e. string after the dot separator
 */
export function getFileExtension(filename: string): string {
  const index = getExtensionStartingIndex(filename);
  return index > 0 ? filename.substring(index + 1).toLocaleLowerCase() : '';
}

/**
 * Returns a file's name without the file extension
 */
export function removeFileExtension(filename: string): string {
  const index = getExtensionStartingIndex(filename);
  return index > 0 ? filename.substring(0, index) : filename;
}

export function snakeToCamel(str: string): string {
  let result = '';
  let armMaj = false;
  for (let i = 0; i < str.length; i += 1) {
    const c = str.charAt(i);
    if (c === '_') {
      armMaj = true;
    } else if (armMaj) {
      result += c.toUpperCase();
      armMaj = false;
    } else {
      result += c;
    }
  }
  return result;
}

const MAJ = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('');

export function camelToSnake(str: string): string {
  let result = '';
  for (let i = 0; i < str.length; i += 1) {
    const c = str.charAt(i);
    result += MAJ.includes(c) ? `_${c.toLowerCase()}` : c;
  }
  return result;
}

export const toUpperFirst = (str: string): string =>
  `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

export const toLowerFirst = (str: string): string =>
  `${str.charAt(0).toLowerCase()}${str.slice(1)}`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertToSQL = (data: any): string | number | boolean | undefined | null => {
  return typeof data === 'object' && data !== null ? JSON.stringify(data) : data;
};

export const padRight = (
  arg: string | number | null | undefined | boolean,
  maxLength: number,
  emptySubstitution = '-',
  fillString = ' '
): string =>
  (!arg ? emptySubstitution : String(arg)).padEnd(maxLength, fillString).substr(0, maxLength);

export const compareSequenceIds = (a: string, b: string): number => {
  const [aPrefix, aId, aSuffix1] = a.split(':');
  const [bPrefix, bId, bSuffix1] = b.split(':');
  if (aPrefix !== bPrefix) {
    return aPrefix.localeCompare(bPrefix);
  }
  if (aId !== bId) {
    const aIdN = Number.parseInt(aId, 36);
    const bIdN = Number.parseInt(bId, 36);
    if (aIdN > bIdN) {
      return 1;
    }
    return -1;
  }
  return aSuffix1.localeCompare(bSuffix1);
};

export const compareEntities = (a: Entity, b: Entity): number => {
  return compareSequenceIds(a.id, b.id);
};

/**
 * Extracts the major version of a given build version.
 * The build version is expected to match the following pattern :
 * X.Y.Z.aaaaaa (aaaaaa = timestamp)
 * The function extracts X.Y from X.Y.Z.aaaaaa.
 * @param buildVersion the build version.
 * @return the major version.
 */
export const getMajorVersion = (buildVersion: string): string =>
  buildVersion.replace(/\.[0-9]+\.[0-9]+$/, '');

export function replaceLineBreaks(value: string, replacement: string): string {
  return value.replace(/\n|\r/g, replacement);
}

export function isValidEmailAddressStructure(emailAddress: string): boolean {
  const validEmailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return validEmailRegex.test(String(emailAddress).toLowerCase());
}
