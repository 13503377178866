import type {
  Entity,
  EquipmentCategory,
  KanbanInfos,
  MainSpecs,
  MarketplaceInfos,
} from '@stimcar/libs-kernel';
import {
  MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID,
  MARKETPLACE_TEXT_ATTACHMENTS_FOLDER_ID,
  MKTP_ENERGY_UNKNOWN,
  MKTP_EQUIPMENT_CATEGORY_COMFORT,
  MKTP_EQUIPMENT_CATEGORY_MULTIMEDIA,
  MKTP_EQUIPMENT_CATEGORY_OTHERS,
  MKTP_EQUIPMENT_CATEGORY_SECURITY,
  MKTP_GEARBOX_UNKNOWN,
  MKTP_PKG_CATEGORY_NA,
  MKTP_WARRANTY_EXTENSION_NOT_SPECIFIED,
  WAN_API_ROUTE_PREFIX,
} from '@stimcar/libs-kernel';
import type {
  CarElement,
  FileUploadResult,
  KanbanColorationCharter,
  SiteConfiguration,
  SiteInfos,
  Stand,
  Workflow,
} from './typings/configuration.js';
import type {
  ContractConfiguration,
  KanbanContract,
  SharedUIContract,
  UIContract,
} from './typings/contract.js';
import type { Civility, Customer } from './typings/customer.js';
import type {
  Attachment,
  AttachmentFolder,
  SparePartManagementType,
  StorageCategories,
} from './typings/general.js';
import type {
  InvoiceInfo,
  Kanban,
  KanbanLogisticInfos,
  Operation,
  PackageDeal,
  PriceableSparePart,
  PurchaseOrder,
  SparePart,
} from './typings/kanban.js';
import type {
  DocumentDesc,
  OperationDesc,
  PackageDealDesc,
  SparePartDesc,
} from './typings/packageDealDesc.js';
import type { RepositoryEntity, SpecificFields } from './typings/repository.js';
import type { Vehicle } from './typings/vehicle.js';
import { MANUAL_SOURCE } from './typings/kanban.js';

export const ACCOUNTING_SERVICE_EMAIL = 'comptabilite@stimcar.center';

export const MARKETPLACE_BUY_PURCHASE_ORDER = 'ACHETEUR';
export const MARKETPLACE_SALE_PURCHASE_ORDER = 'VENDEUR';
export const MARKETPLACE_PURCHASE_ORDERS = [
  MARKETPLACE_BUY_PURCHASE_ORDER,
  MARKETPLACE_SALE_PURCHASE_ORDER,
] as const;
export type MarketplacePurchaseOrder = (typeof MARKETPLACE_PURCHASE_ORDERS)[number];

export const THUMBNAILS_FOLDER_NAME = 'thumbnails';

export const MARKETPLACE_PHOTO_ATTACHMENT_FOLDER: AttachmentFolder = {
  id: MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID,
  isShareable: false,
};

export const MARKETPLACE_TEXT_ATTACHMENT_FOLDER: AttachmentFolder = {
  id: MARKETPLACE_TEXT_ATTACHMENTS_FOLDER_ID,
  isShareable: false,
};

export const KANBAN_ATTRIBUTES: Record<string, string> = {
  DELEGATION_SITE: 'delegationSite',
  DELEGATED_ID: 'delegatedId',
  // Tells whether the delegated kanban is closed or archived
  DELEGATED_STATUS: 'delegatedStatus',
};

export const PACKAGE_DEAL_ATTRIBUTES = {
  DELEGATED: 'delegated',
  DELEGATED_ID: KANBAN_ATTRIBUTES.DELEGATED_ID,
  DELEGATING_ID: 'delegatingId',
};

export const OPERATION_ATTRIBUTES = {
  WORKSHOP_POST: 'post',
  DELEGATED_ID: KANBAN_ATTRIBUTES.DELEGATED_ID,
  DELEGATING_ID: PACKAGE_DEAL_ATTRIBUTES.DELEGATING_ID,
  WORKLOAD_BEFORE_DELEGATION: 'workloadBeforeDelegation',
  WORKLOAD_EXPRESSION_BEFORE_DELEGATION: 'workloadExpressionBeforeDelegation',
};

export const COACH_ATTRIBUTE_NAME = 'coach';

export const VALIDATION_CODE_DURATION_IN_MINUTES = 15;

export const SUPER_ADMIN_PROFILE_NAME = 'admin';

export type AuthenticationTypes = 'password' | 'pin';

export const KANBAN_IDENTITY_PICTURE_CATEGORY: StorageCategories = 'kanban';
export const KANBAN_IDENTITY_PICTURE_FOLDER = 'general';
export const KANBAN_IDENTITY_PICTURE_NAME = 'main.webp';

export const WORKSHOP_POST_ID_SEPARATOR = ':';

export const WORKSHOP_IMPLANTATION_ANOMALY_POST_CATEGORY_ID = 'anomaly';

export const ADMIN_PACKAGE_DEAL_CODE = 'ADMIN';
export const ESTH_PACKAGE_DEAL_CODE = 'ESTH';
export const TC_PACKAGE_DEAL_CODE = 'CT';
export const MARKETPLACE_SELL_PACKAGE_DEAL_CODE = 'MKTPSELL';

export const MILEAGE_GLOBAL_VARIABLE_NAME = 'kms';
export const HOURLY_RATE_GLOBAL_VARIABLE_NAME = 'hourlyRate';
export const CONTRACT_GLOBAL_VARIABLE_NAME = 'contract';
export const CUSTOMER_GLOBAL_VARIABLE_NAME = 'customer';
export const REGISTRATION_DATE_GLOBAL_VARIABLE_NAME = 'registration';
export const TAG_GLOBAL_VARIABLE_NAME_PREFIX = 'nb';

export const UPSTREAM_TC_STAND_ID = 'CTA';
export const EXPERTISE_STAND_ID = 'EXP';
export const CUSTOMER_VALIDATION_STAND_ID = 'VEXP';
export const MARKETPLACE_STAND_ID = 'MKTP';
export const SPARE_PARTS_DEFAULT_STAND_ID = 'PR';
export const DSP_STAND_ID = 'DSP';
export const TUBE_STAND_ID = 'TUBE';
export const TC_STAND_ID = 'CT';
export const DELIVERY_STAND_ID = 'LIV';
export const ADMIN_STAND_ID = 'ADMIN';

export const MKTP_PKG_DEAL_CODE_PREFIX = 'FRAISFIXE';
export const MKTP_PKG_DEAL_CODE_VARIABLE_FEE = `${MKTP_PKG_DEAL_CODE_PREFIX}1`;
export const MKTP_PKG_DEAL_CODE_SALE_EXPERTISE = `${MKTP_PKG_DEAL_CODE_PREFIX}6`;
export const MKTP_PKG_DEAL_CODE_BUY_EXPERTISE = `${MKTP_PKG_DEAL_CODE_PREFIX}6bis`;
export const MKTP_PKG_DEAL_CODE_CLIENT_DELIVERY = `${MKTP_PKG_DEAL_CODE_PREFIX}15`;
export const MKTP_PKG_DEAL_VARIABLE_FEES_MIN_PRICE_VARIABLE = 'minPrice';
export const MKTP_PKG_DEAL_VARIABLE_FEES_MAX_PRICE_VARIABLE = 'maxPrice';
export const MKTP_PKG_DEAL_VARIABLE_FEES_RATE_VARIABLE = 'rate';

export const PACKAGE_DEAL_CODES_THAT_CANNOT_BE_REMOVED = [
  ADMIN_PACKAGE_DEAL_CODE,
  'EXP',
  'EXP1',
  'EXP2',
  'EXP3',
  'EXPMULBDC',
  'EXPPARBDC',
  'EXPCAR',
  'EXPLIGH',
  'CQUA',
  'CONS',
  MARKETPLACE_SELL_PACKAGE_DEAL_CODE,
];

export const EXPERTISE_ATTACHMENTS_FOLDER: AttachmentFolder = {
  id: 'expertise',
  isShareable: true,
};
export const REGISTRATION_DOCUMENTS_ATTACHMENTS_FOLDER: AttachmentFolder = {
  id: 'registrationDocuments',
  isShareable: true,
};
export const TC_ATTACHMENT_FOLDER: AttachmentFolder = {
  id: 'tc',
  isShareable: true,
};
export const ESTIMATE_ATTACHMENT_FOLDER: AttachmentFolder = {
  id: 'Estimate',
  isShareable: true,
};
export const ACCEPTANCE_ATTACHMENT_FOLDER: AttachmentFolder = {
  id: 'Acceptance',
  isShareable: true,
};
export const INVOICE_ATTACHMENT_FOLDER: AttachmentFolder = {
  id: 'Invoice',
  isShareable: true,
};
export const QUALITY_CONTROL_ATTACHMENT_FOLDER: AttachmentFolder = {
  id: 'Quality',
  isShareable: true,
};
// Fiche de travaux
export const WORK_SHEET_ATTACHMENTS_FOLDER: AttachmentFolder = {
  id: 'WorkSheet',
  isShareable: false,
};

export const WORK_SHEET_ATTRIBUTE_NAME = 'workSheet';

export const HttpErrorCodes = {
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  NOT_MODIFIED: 304,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  GONE: 410,
  UNSUPPORTED_MEDIA_TYPE: 415,
  RANGE_NOT_SATISFIABLE: 416,
  INTERNAL_SERVER_ERROR: 500,
  INVALID_BROWSER_TOKEN: 462,
  BUILD_VERSION_MISMATCH: 463,
};

/* SSE Messages constants */
export const PING_SERVER_MESSAGE = 'ping';
export const SSE_ACCESS_REVOKED = 'sseAccessRevoked';

/* Session management constants */
export const APP_BROWSER_SESSION_TOKEN = 'AppBrowserSessionToken';

/* Invoicing related constants */
export const INVOICE_FIRM_ID_ATTRIBUTE = 'invoiceFirmId';
export const INVOICE_SITE_ID_ATTRIBUTE = 'invoiceSiteId';
export const INVOICE_ID_ATTRIBUTE = 'InvoiceId';
export const INVOICE_REF_ATTRIBUTE = 'InvoiceRef';

export const CommonRoutes = {
  BUILD_VERSION: `${WAN_API_ROUTE_PREFIX}/buildVersion`,
  AUTHENTICATE: `${WAN_API_ROUTE_PREFIX}/authenticate`,
  LOGOUT: `${WAN_API_ROUTE_PREFIX}/logout`,
  AUTHENTICATED_USER: `${WAN_API_ROUTE_PREFIX}/authenticatedUser`,
  USER_INFO: `${WAN_API_ROUTE_PREFIX}/userInfo`,
  GET_SITES: `${WAN_API_ROUTE_PREFIX}/sites`,
  BROWSER_REGISTER: `${WAN_API_ROUTE_PREFIX}/browser/register`,
};

export const WORKSHOP_PASSWORD = '&QEqAR9b*H^8%7jZ';

export const EMPTY_ENTITY: Entity = { id: '' };

export const EMPTY_REPOSITORY_ENTITY: RepositoryEntity = {
  ...EMPTY_ENTITY,
  sequenceId: 0,
  timestamp: 0,
  status: 'open',
};

export const CIVILITIES: readonly Civility[] = ['notset', 'company', 'mr', 'mrs', 'mr&mrs'];

export const EMPTY_CUSTOMER_SPECIFIC_FIELDS: SpecificFields<Customer> = {
  contract: '',
  shortName: '',
  individual: false,
  company: '',
  civility: 'notset',
  lastName: '',
  firstName: '',
  street: '',
  zipCode: '',
  city: '',
  phone: '',
  email: '',
  invoiceId: 0,
  ignoreVAT: false,
};

export const EMPTY_CUSTOMER: Customer = {
  ...EMPTY_REPOSITORY_ENTITY,
  ...EMPTY_CUSTOMER_SPECIFIC_FIELDS,
};

export const EMPTY_KANBAN_INFOS: KanbanInfos = {
  brand: '',
  model: '',
  vin: '',
  license: '',
  motor: '',
  mileage: 0,
  dateOfRegistration: null,
  color: '',
};

/* Empty objects constants */
export const EMPTY_PRICEABLE_SPARE_PART: PriceableSparePart = {
  ...EMPTY_ENTITY,
  label: '',
  price: 0,
  commentForCustomer: '',
  quantity: 1,
  managementType: 'fullyManagedByStimcar',
  provider: null,
  standId: SPARE_PARTS_DEFAULT_STAND_ID,
  dateOfReception: null,
};

export const EMPTY_SPARE_PART_MANAGED_BY_US: SparePart = {
  ...EMPTY_PRICEABLE_SPARE_PART,
  comment: '',
  commentForWorkshop: '',
  providerUnitPrice: 0,
  priceIsOverridden: false,
  dateOfOrder: null,
  dateOfReference: null,
  estimatedDateOfReception: null,
};

export const EMPTY_SPARE_PART_MANAGED_BY_CUSTOMER: SparePart = {
  ...EMPTY_SPARE_PART_MANAGED_BY_US,
  managementType: 'fullyManagedByCustomer',
};

export const EMPTY_SPARE_PART_ORDERED_BY_STIMCAR_FROM_CUSTOMERS_CATALOG: SparePart = {
  ...EMPTY_SPARE_PART_MANAGED_BY_US,
  managementType: 'orderedByStimcarFromCustomersCatalog',
};

export const EMPTY_STAND: Stand = {
  id: '',
  iconClass: 's/car',
  implantations: [],
};

export const EMPTY_UPLOAD_RETURN_VALUE: FileUploadResult = {
  success: undefined,
  warnings: [],
  errors: [],
};

export const EMPTY_OPERATION: Operation = {
  ...EMPTY_ENTITY,
  orderIndex: 0,
  label: '',
  workload: 0,
  standId: '',
  operationDescId: '',
  workloadExpression: '',
  attributes: {},
  completionDate: null,
  user: null,
};

export const DEFAULT_VALIDATE_EXPERTISE_OPERATION: Omit<Operation, 'id' | 'standId'> = {
  ...EMPTY_OPERATION,
  orderIndex: 1,
  label: 'Validation',
  type: 'Validation',
};

export const EMPTY_ATTACHMENT: Attachment = {
  id: '',
  folder: '',
  name: '',
};

export const EMPTY_PACKAGE_DEAL: PackageDeal = {
  ...EMPTY_ENTITY,
  packageDealDescId: '',
  code: '',
  label: '',
  carElement: undefined,
  tags: [],
  durationExpression: '',
  priceExpression: '0',
  hint: '',
  variables: {},
  comment: '',
  duration: 0,
  price: 0,
  overridablePrice: false,
  priceIsOverridden: false,
  spareParts: [],
  operations: [],
  status: null,
  recommendedByExpert: true,
  attributes: {},
  attachments: [],
  estimateComment: '',
  unachievableReason: null,
  category: 'AUTRE',
};

export const WORKSHOP_STARTING_HOUR = 5;

export const HOURLY_RATE_DEFAULT_VALUE = 57;
export const ROUND_PRICE_TO_DEFAULT_VALUE = 1;
export const SPARE_PART_MARGIN_DEFAULT_PERCENTAGE = 0.3;
export const SPARE_PART_DEFAULT_MANAGEMENT_TYPE: SparePartManagementType = 'fullyManagedByStimcar';

export const EMPTY_MEMO_DESCS_CONFIGURATION = { BUMP: [], EXTE: [], INTE: [], MECA: [], MISC: [] };

export const EMPTY_MANDATORY_FIELDS_CONFIGURATION = {
  purchaseOrders: false,
};

export const EMPTY_CONTRACT_CONFIGURATION: ContractConfiguration = {
  estimateValidationMailingList: [],
  sparePartManagementType: SPARE_PART_DEFAULT_MANAGEMENT_TYPE,
  sparePartMarginPercentage: SPARE_PART_MARGIN_DEFAULT_PERCENTAGE,
  hourlyRate: HOURLY_RATE_DEFAULT_VALUE,
  pkgDealDescCodesForKanban: {},
  pkgDealDescCodesForPurchaseOrder: {},
  roundPriceTo: ROUND_PRICE_TO_DEFAULT_VALUE,
  documents: [],
  attributeDescs: [],
  upstreamTC: false,
  mandatoryFields: EMPTY_MANDATORY_FIELDS_CONFIGURATION,
  handleMultiplePurchaseOrders: false,
  standardPicturesConfigurationKey: '',
  standardPicturesConfiguration: [],
  estimateMention: '',
  expertHints: '',
  memoDescs: EMPTY_MEMO_DESCS_CONFIGURATION,
};

export const EMPTY_CAR_ELEMENT: CarElement = {
  ...EMPTY_REPOSITORY_ENTITY,
  category: 'EXTE',
  index: 0,
  label: '',
  shapes: [],
  dirty: true,
};

export const EMPTY_SHARED_UI_CONTRACT: SharedUIContract = {
  code: '',
  packageDealDatabase: '',
  documents: EMPTY_CONTRACT_CONFIGURATION.documents,
  attributeDescs: EMPTY_CONTRACT_CONFIGURATION.attributeDescs,
  estimateMention: EMPTY_CONTRACT_CONFIGURATION.estimateMention,
  memoDescs: EMPTY_CONTRACT_CONFIGURATION.memoDescs,
};

export const EMPTY_UICONTRACT: UIContract = {
  ...EMPTY_SHARED_UI_CONTRACT,
  sparePartManagementType: EMPTY_CONTRACT_CONFIGURATION.sparePartManagementType,
  sparePartMarginPercentage: EMPTY_CONTRACT_CONFIGURATION.sparePartMarginPercentage,
  hourlyRate: EMPTY_CONTRACT_CONFIGURATION.hourlyRate,
  pkgDealDescCodesForKanban: EMPTY_CONTRACT_CONFIGURATION.pkgDealDescCodesForKanban,
  pkgDealDescCodesForPurchaseOrder: EMPTY_CONTRACT_CONFIGURATION.pkgDealDescCodesForPurchaseOrder,
  roundPriceTo: EMPTY_CONTRACT_CONFIGURATION.roundPriceTo,
  upstreamTC: EMPTY_CONTRACT_CONFIGURATION.upstreamTC,
  mandatoryFields: EMPTY_CONTRACT_CONFIGURATION.mandatoryFields,
  expertHints: EMPTY_CONTRACT_CONFIGURATION.expertHints,
  standardPicturesConfiguration: EMPTY_CONTRACT_CONFIGURATION.standardPicturesConfiguration,
};

export const EMPTY_KANBAN_LOGISTIC_INFOS: KanbanLogisticInfos = {
  keyNumber: '',
  locationHistory: [],
};

export const EMPTY_PURCHASE_ORDER: PurchaseOrder = {
  ...EMPTY_ENTITY,
  purchaseNumber: '',
  label: null,
  invoiceInfos: [],
};

export const EMPTY_INVOICE_INFO: InvoiceInfo = {
  ...EMPTY_ENTITY,
  reference: null,
  amount: null,
  amountWithVAT: null,
  invoiceId: null,
  invoiceFirmId: null,
  isRefund: null,
  refundedInvoiceId: null,
};

export const EMPTY_KANBAN_CONTRACT: KanbanContract = {
  code: EMPTY_UICONTRACT.code,
  packageDealDatabase: EMPTY_UICONTRACT.packageDealDatabase,
  configuration: {
    hourlyRate: EMPTY_CONTRACT_CONFIGURATION.hourlyRate,
    sparePartManagementType: EMPTY_CONTRACT_CONFIGURATION.sparePartManagementType,
    sparePartMarginPercentage: EMPTY_CONTRACT_CONFIGURATION.sparePartMarginPercentage,
    roundPriceTo: EMPTY_CONTRACT_CONFIGURATION.roundPriceTo,
    mandatoryFields: EMPTY_CONTRACT_CONFIGURATION.mandatoryFields,
    pkgDealDescCodesForKanban: EMPTY_CONTRACT_CONFIGURATION.pkgDealDescCodesForKanban,
    pkgDealDescCodesForPurchaseOrder: EMPTY_CONTRACT_CONFIGURATION.pkgDealDescCodesForPurchaseOrder,
  },
};

export const EMPTY_KANBAN: Kanban = {
  ...EMPTY_REPOSITORY_ENTITY,
  origin: {
    source: MANUAL_SOURCE,
    id: null,
  },
  creationDate: 0,
  dueDate: null,
  refitEndDate: null,
  workflowId: '',
  infos: EMPTY_KANBAN_INFOS,
  customer: EMPTY_CUSTOMER_SPECIFIC_FIELDS,
  messages: [],
  logisticInfos: EMPTY_KANBAN_LOGISTIC_INFOS,
  handlings: [],
  packageDeals: [],
  estimateComment: '',
  deliveryComment: '',
  contract: EMPTY_KANBAN_CONTRACT,
  dirty: false,
  memos: {},
  purchaseOrders: [],
  attributes: {},
  defects: [],
};

export const EMPTY_SITE_INFOS: SiteInfos = {
  companyName: '',
  address: '',
  workshopAddress: '',
  invoiceFirmId: 0,
  invoiceSiteId: 0,
  billingCodePrefix: '',
};

export const EMPTY_MAIN_SPECS: MainSpecs = {
  energy: MKTP_ENERGY_UNKNOWN,
  gearbox: MKTP_GEARBOX_UNKNOWN,
  horsepower: 0,
  doors: 0,
};

export const EMPTY_MARKETPLACE_EQUIPMENTS: Record<EquipmentCategory, readonly string[]> = {
  [MKTP_EQUIPMENT_CATEGORY_SECURITY]: [],
  [MKTP_EQUIPMENT_CATEGORY_COMFORT]: [],
  [MKTP_EQUIPMENT_CATEGORY_MULTIMEDIA]: [],
  [MKTP_EQUIPMENT_CATEGORY_OTHERS]: [],
};

export const EMPTY_MARKETPLACE_INFO: MarketplaceInfos = {
  mandate: '',
  inputPrice: 0,
  negotiation: 0,
  depositThirdPartyService: '',
  deposit: 0,
  canceledPackageDealIds: [],
  textAttachment: '',
  mainSpecs: EMPTY_MAIN_SPECS,
  equipments: EMPTY_MARKETPLACE_EQUIPMENTS,
  warrantyExtension: MKTP_WARRANTY_EXTENSION_NOT_SPECIFIED,
  loanAmount: 0,
  stimerId: '',
  paymentHistory: [],
};

export const DEFAULT_KANBAN_COLORATION_CHARTER: KanbanColorationCharter = {
  ageUnderWhichKanbansAreYoung: 0,
  ageAboveWhichKanbansAreOld: 0,
  dueDateThreshold: 0,
};

export const EMPTY_SITE_CONFIGURATION: SiteConfiguration = {
  stands: [],
  iconDictionary: {},
  workflows: [],
  packageDealDatabases: [],
  infos: EMPTY_SITE_INFOS,
  displayConfiguration: {
    kanbanColorationCharter: DEFAULT_KANBAN_COLORATION_CHARTER,
  },
  computeIconFunction: '',
  packageDealDecorators: [],
  shiftsConfiguration: {
    expertise: {
      collaboratorsCount: {
        shift1: 2,
        shift2: 0,
        shift3: 0,
      },
      factor: 1 / 1.7,
      expectedWorkloadByCollaborator: 7,
      startHour: 7,
      startMinute: 0,
    },
    workshop: {
      collaboratorsCount: {
        shift1: 12,
        shift2: 10,
        shift3: 0,
      },
      factor: 1,
      expectedWorkloadByCollaborator: 7,
      startHour: 5,
      startMinute: 30,
    },
  },
  subcontractors: [],
  marketplaceEquipments: {
    security: [],
    comfort: [],
    multimedia: [],
    others: [],
  },
};

export const EMPTY_WORKFLOW: Workflow = {
  id: '',
  description: '',
  definition: { id: '' },
};

export const DEFAULT_WORKFLOW: Workflow = {
  ...EMPTY_WORKFLOW,
  id: 'default',
  description: 'This is the default workflow for the site',
};

export const EMPTY_SPARE_PART_DESC: SparePartDesc = {
  ...EMPTY_ENTITY,
  label: '',
};

export const EMPTY_OPERATION_DESC: OperationDesc = {
  ...EMPTY_ENTITY,
  orderIndex: 0,
  label: '',
  standId: '',
  workloadExpression: '',
  attributes: {},
};

export const EMPTY_DOCUMENT_DESC: DocumentDesc = {
  ...EMPTY_ENTITY,
  label: '',
  targetFolder: '',
  standId: '',
};

export const EMPTY_SPAREPART_REFERENCE_OPERATION: Omit<
  Operation,
  'id' | 'completionDate' | 'user' | 'standId'
> = {
  label: 'Référencer et chiffrer',
  orderIndex: 0,
  operationDescId: '',
  attributes: {},
  workload: 0,
  workloadExpression: '0',
  type: 'ReferenceSparePart',
};

export const DASH_PREFIX = '- ';

export const SUBCONTRACTOR_REQUEST_MESSAGE_ICON_ID = 'exclamation-triangle';

export const MARKDOWN_FILE_EXTENSION = '.md';

export const EMPTY_VEHICLE_SPECIFIC_FIELDS: SpecificFields<Vehicle> = {
  ...EMPTY_KANBAN_INFOS,
  customerId: '',
  estimateRequests: [],
  dateOfRegistration: 0,
};

export const EMPTY_VEHICLE: Vehicle = {
  ...EMPTY_REPOSITORY_ENTITY,
  ...EMPTY_VEHICLE_SPECIFIC_FIELDS,
};

export const PACKAGE_DEAL_CATEGORY_IDS = ['AUTRE', 'CARRO', 'EXP', 'MECA', 'PR'] as const;

export const EMPTY_PACKAGE_DEAL_DESC: PackageDealDesc = {
  ...EMPTY_REPOSITORY_ENTITY,
  code: '',
  carElementIds: [],
  label: '',
  hint: '',
  sparePartDescs: [],
  priceExpression: '',
  durationExpression: '',
  category: 'AUTRE',
  isMarketplace: false,
  marketplaceBuyCategory: MKTP_PKG_CATEGORY_NA,
  marketplaceSaleCategory: MKTP_PKG_CATEGORY_NA,
  tags: [],
  variableDescs: {},
  overridablePrice: false,
  ignoreVAT: false,
  database: '',
  attributes: {},
  operationDescs: [],
  isSubcontractable: false,
  documentDescs: [],
};

export const AVAILABLE_BRANDS: readonly string[] = [
  'Abarth',
  'Alfa Romeo',
  'Alpine',
  'Aston Martin',
  'Audi',
  'Bentley',
  'BMW',
  'Chevrolet',
  'Citroën',
  'Cupra',
  'Dacia',
  'DS',
  'Ferrari',
  'Fiat',
  'Ford',
  'Honda',
  'Hyundai',
  'Infiniti',
  'Iveco',
  'Jaguar',
  'Jeep',
  'Kia',
  'Lancia',
  'Land Rover',
  'Lexus',
  'Lotus',
  'Lynk & Co',
  'Maserati',
  'Mazda',
  'Mercedes-Benz',
  'Mini',
  'Mitsubishi',
  'MG',
  'Nissan',
  'Opel',
  'Peugeot',
  'Porsche',
  'Renault',
  'Seat',
  'Skoda',
  'Smart',
  'Subaru',
  'Suzuki',
  'Tesla',
  'Toyota',
  'Volkswagen',
  'Volvo',
];
