import type { ActionContext } from '@stimcar/libs-uikernel';
import type {
  MonitoredDevice,
  MonitoredDeviceCommand,
  MonitoredDeviceCommandLog,
} from '@stimcar/monitor-libs-common';
import { nonnull } from '@stimcar/libs-kernel';
import { MonitorBackendRoutes } from '@stimcar/monitor-libs-common';
import type { DeviceDetailsState, Store, StoreState } from './state/typings/store.js';

export const extractTunnelPortFromLog = (log: string): number | undefined => {
  return log.includes('stimcar.link:')
    ? Number.parseInt(log.replace(/^.*stimcar.link:/, ''), 10)
    : undefined;
};

export const extractTunnelPortFromLogs = (
  logs: readonly MonitoredDeviceCommandLog[]
): number | undefined => {
  return logs.reduce<number | undefined>(
    (p, c) => (!p ? extractTunnelPortFromLog(c.data) : p),
    undefined
  );
};

export const isTunneledCommand = (
  input: MonitoredDeviceCommand['type'] | MonitoredDeviceCommand
): boolean => {
  const type = typeof input === 'string' ? input : input.type;
  return ['vnctunnel', 'sshtunnel'].includes(type);
};

export const injectNewCommandIntoStateAction = async (
  { getState, actionDispatch }: ActionContext<Store, DeviceDetailsState>,
  deviceShortId: string,
  newCommand: MonitoredDeviceCommand,
  autoOpenVncOrSshOrScreenshotViewer?: boolean
  // eslint-disable-next-line @typescript-eslint/require-await
): Promise<void> => {
  const { commands, expandedCommandIds } = getState();
  actionDispatch.setProperty('commands', [
    ...commands,
    { ...newCommand, autoOpenVncOrSshOrScreenshotViewer, deviceShortId, logs: [] },
  ]);
  actionDispatch.setProperty('expandedCommandIds', [...expandedCommandIds, String(newCommand.id)]);
};

export const newCommandAction = async (
  { httpClient, getState, actionDispatch }: ActionContext<Store, DeviceDetailsState>,
  type: MonitoredDeviceCommand['type'],
  args: string,
  autoOpenVncOrSshViewer?: boolean
): Promise<void> => {
  const { selectedDeviceShortId, expandedCommandIds } = getState();
  const deviceShortId = nonnull(selectedDeviceShortId);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const newCommand = await httpClient.httpPostAsJSON<any, MonitoredDeviceCommand>(
    MonitorBackendRoutes.NEW_DEVICE_COMMAND(deviceShortId),
    { type, arguments: args }
  );
  await actionDispatch.exec(
    injectNewCommandIntoStateAction,
    deviceShortId,
    newCommand,
    autoOpenVncOrSshViewer
  );
  actionDispatch.setProperty('expandedCommandIds', [...expandedCommandIds, String(newCommand.id)]);
};

export const selectDeviceAction = async (
  { actionDispatch, httpClient, getGlobalState }: ActionContext<Store, DeviceDetailsState>,
  selectedDeviceShortId: string | undefined,
  updateBrowserHistory = true
): Promise<void> => {
  actionDispatch.setProperty('selectedDeviceShortId', selectedDeviceShortId);
  if (!selectedDeviceShortId) {
    actionDispatch.setProperty('commands', []);
  } else {
    const commands = await httpClient.httpGetAsJson<readonly MonitoredDeviceCommand[]>(
      MonitorBackendRoutes.DEVICE_COMMAND_LIST(selectedDeviceShortId)
    );
    actionDispatch.setProperty(
      'commands',
      commands.map((c) => {
        return { ...c, deviceShortId: selectedDeviceShortId, logs: [] };
      })
    );
    actionDispatch.setProperty('expandedCommandIds', []);
  }
  // Update the browser history (updates the current URL with a short id
  // selector if a device is selected)
  if (updateBrowserHistory) {
    const { devices } = getGlobalState();
    const selectedDevice = devices.find(({ shortId }) => shortId === selectedDeviceShortId);
    window.history.pushState(
      undefined,
      '', // unused (https://developer.mozilla.org/en-US/docs/Web/API/History/pushState#unused)
      selectedDeviceShortId ? `/?shortId=${selectedDeviceShortId}` : '/' // new URL
    );
    // Update title
    const title =
      selectedDevice && selectedDevice.label
        ? `${selectedDevice.location} / ${selectedDevice.label}`
        : `${!selectedDeviceShortId ? 'Monitor' : selectedDeviceShortId}`;
    document.title = title;
  }
};

export const openScreenshotAction = async ({
  actionDispatch,
  getState,
}: // eslint-disable-next-line @typescript-eslint/require-await
ActionContext<Store, StoreState>): Promise<void> => {
  const { deviceDetails } = getState();
  const { selectedDeviceShortId } = deviceDetails;
  const imageModalDispatch = actionDispatch.scopeProperty('imageModal');
  imageModalDispatch.applyPayload({
    active: true,
    imageIndex: 0,
    imagesUrls: [MonitorBackendRoutes.DEVICE_SCREENSHOT(nonnull(selectedDeviceShortId))],
  });
};

export const openRemoveDeviceDialogAction = async (
  {
    globalActionDispatch,
  }: // eslint-disable-next-line @typescript-eslint/require-await
  ActionContext<Store, StoreState>,
  device: MonitoredDevice
  // eslint-disable-next-line @typescript-eslint/require-await
): Promise<void> => {
  const dispatch = globalActionDispatch.scopeProperty('confirmRemoveDeviceDialog');
  dispatch.setProperty('active', true);
  dispatch.setProperty('shortId', device.shortId);
};
