import type { JSX } from 'react';
import type { Terminal } from 'xterm';
import { AnsiUp } from 'ansi_up';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import type { MonitoredDeviceCommandLog } from '@stimcar/monitor-libs-common';
import { Logger, nonnull } from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useGetState,
  useSelectorWithChangeTrigger,
} from '@stimcar/libs-uikernel';
import {
  Button,
  ClickableIcon,
  FaIcon,
  ReadOnlyInputFormField,
  ToggleNestedButton,
} from '@stimcar/libs-uitoolkit';
import { MonitorBackendRoutes } from '@stimcar/monitor-libs-common';
import type { DeviceDetailsState, Store, UIMonitoredDeviceCommand } from './state/typings/store.js';
import {
  extractTunnelPortFromLogs,
  isTunneledCommand,
  newCommandAction,
  openScreenshotAction,
} from './DeviceDetailsActions.js';
import { darkenColor } from './utils/colorHelper.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

const XTERM_COMPLETED_BG_COLOR = '#cef9de';
const XTERM_RUNNING_BG_COLOR = '#d7d3ff';
const XTERM_TEXT_COLOR = '#333';

const HTTP_PORT = ((): number => {
  const { port, protocol } = document.location;
  if (port === '') {
    return protocol === 'https:' ? 443 : 80;
  }
  return Number.parseInt(port, 10);
})();

const cancelAutoOpenViewerActions = async (
  {
    actionDispatch,
    getState,
  }: // eslint-disable-next-line @typescript-eslint/require-await
  ActionContext<Store, DeviceDetailsState>,
  commandId: string
  // eslint-disable-next-line @typescript-eslint/require-await
): Promise<void> => {
  // Disable the auto open viewer flag
  const { commands } = getState();
  actionDispatch.setProperty(
    'commands',
    commands.map((c) =>
      c.id === commandId ? { ...c, autoOpenVncOrSshOrScreenshotViewer: undefined } : c
    )
  );
};

// eslint-disable-next-line new-cap
const ansiConverter = new AnsiUp();

export interface DeviceCommandProps extends AppProps<Store> {
  readonly command: UIMonitoredDeviceCommand;
}

export function DeviceCommand({ $gs, command }: DeviceCommandProps): JSX.Element {
  const { $deviceDetails } = $gs;
  const expandedCommandIds = useGetState($deviceDetails.$expandedCommandIds);
  const devices = useGetState($gs.$devices);

  const device = useMemo(() => {
    return nonnull(devices.find((d) => d.id === command.deviceId));
  }, [command.deviceId, devices]);

  const killCommandActionCallback = useActionCallback(
    async ({
      actionDispatch,
      getState,
    }: ActionContext<Store, DeviceDetailsState>): Promise<void> => {
      const { expandedCommandIds: expandedIds } = getState();
      await actionDispatch.exec(newCommandAction, 'kill', String(command.pid));
      actionDispatch.setProperty(
        'expandedCommandIds',
        expandedIds.filter((id) => id !== String(command.id))
      );
    },
    [command.id, command.pid],
    $deviceDetails
  );

  const commandIsExpanded = expandedCommandIds.includes(String(command.id));

  const onExpandCollapseCommandCallback = useActionCallback(
    async ({
      actionDispatch,
      getState,
      httpClient,
    }: ActionContext<Store, DeviceDetailsState>): Promise<void> => {
      const { commands, expandedCommandIds: expandedIds } = getState();
      let logs: readonly MonitoredDeviceCommandLog[] = [];
      if (expandedIds.includes(String(command.id))) {
        logs = await httpClient.httpGetAsJson(
          MonitorBackendRoutes.DEVICE_COMMAND_LOG_LIST(device.shortId, String(command.id))
        );
      }
      actionDispatch.setProperty(
        'commands',
        commands.map((c) =>
          c.id === command.id
            ? {
                ...c,
                tunnelPort: isTunneledCommand(c) ? extractTunnelPortFromLogs(logs) : undefined,
                logs,
              }
            : c
        )
      );
    },
    [command.id, device.shortId],
    $deviceDetails
  );

  const vncOrSshViewerUrl = useMemo(() => {
    if (command.tunnelPort) {
      switch (command.type) {
        case 'vnctunnel':
          return `/novnc/vnc.html?host=${document.location.hostname}&port=${HTTP_PORT}&password=${
            command.arguments
          }&autoconnect=true&path=tcpwstunnel/${command.tunnelPort}&encrypt=${
            document.location.protocol === 'https:'
          }`;
        case 'sshtunnel':
          return `/xterm/index.html?sshTargetLabel=${
            !device.label ? device.shortId : device.label
          }&sshTunnelPort=${command.tunnelPort}&autoCloseWhenDisconnected=true`;
        default:
      }
    }
    return undefined;
  }, [command.arguments, command.tunnelPort, command.type, device.label, device.shortId]);

  const openVncOrSshViewerUrlCallback = useCallback(() => {
    window.open(vncOrSshViewerUrl);
  }, [vncOrSshViewerUrl]);

  const asyncOpenViewerEffect = useActionCallback(
    async ({
      actionDispatch,
    }: // eslint-disable-next-line @typescript-eslint/require-await
    ActionContext<Store, DeviceDetailsState>): Promise<void> => {
      // Open the viewer
      openVncOrSshViewerUrlCallback();
      // cancel auto open viewer
      await actionDispatch.exec(cancelAutoOpenViewerActions, command.id);
    },
    [command.id, openVncOrSshViewerUrlCallback],
    $deviceDetails
  );

  useEffect(() => {
    if (vncOrSshViewerUrl && command.autoOpenVncOrSshOrScreenshotViewer) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      asyncOpenViewerEffect();
    }
  }, [asyncOpenViewerEffect, command.autoOpenVncOrSshOrScreenshotViewer, vncOrSshViewerUrl]);

  const asyncOpenScreenshotEffect = useActionCallback(
    async ({
      actionDispatch,
      globalActionDispatch,
    }: // eslint-disable-next-line @typescript-eslint/require-await
    ActionContext<Store, DeviceDetailsState>): Promise<void> => {
      // Open screenshot
      await globalActionDispatch.exec(openScreenshotAction);
      // cancel auto open viewer
      await actionDispatch.exec(cancelAutoOpenViewerActions, command.id);
    },
    [command.id],
    $deviceDetails
  );
  useEffect(() => {
    if (
      command.type === 'screenshot' &&
      command.autoOpenVncOrSshOrScreenshotViewer &&
      command.completed
    ) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      asyncOpenScreenshotEffect();
    }
  }, [
    asyncOpenScreenshotEffect,
    command.autoOpenVncOrSshOrScreenshotViewer,
    command.completed,
    command.type,
  ]);

  const manualConnectionString = useMemo(() => {
    if (!command.tunnelPort) {
      return 'Waiting for tunnel port detection...';
    }
    if (command.type === 'vnctunnel') {
      return `vnc://stimcar.link:${command.tunnelPort} (password: ${command.arguments})`;
    }
    if (command.type === 'sshtunnel') {
      return `ssh <user>@stimcar.link -p ${command.tunnelPort}`;
    }
    return undefined;
  }, [command.arguments, command.tunnelPort, command.type]);

  const copyManualConnectionStringToClipboard = useCallback(async (): Promise<void> => {
    await navigator.clipboard.writeText(nonnull(manualConnectionString));
  }, [manualConnectionString]);

  const terminalRef = useRef<Terminal>(null);

  useEffect(() => {
    const terminal = terminalRef.current;
    if (terminal) {
      const ttyWidth = terminal.cols;
      const rows = command.logs.reduce<number>(
        (p, c) => p + Math.ceil(c.data.length / ttyWidth),
        0
      );
      let terminalRows = rows;
      if (terminalRows > 15) {
        terminalRows = 15;
      } else if (terminalRows < 8) {
        terminalRows = 8;
      }
      terminal.resize(terminal.cols - 1 /* for the scrollbars */, terminalRows);
      terminal.write(
        [...command.logs]
          .sort((l1, l2) => l1.orderIndex - l2.orderIndex)
          .map((l) => l.data)
          .join('\r\n')
      );
    }
  }, [command.logs]);

  const sortedLogs = useMemo(() => {
    return [...command.logs].sort((l1, l2) => l1.orderIndex - l2.orderIndex);
  }, [command.logs]);

  const codeTagStyle = useMemo(
    () => ({
      backgroundColor: command.completed ? XTERM_COMPLETED_BG_COLOR : XTERM_RUNNING_BG_COLOR,
    }),
    [command.completed]
  );

  const logsContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (logsContainerRef.current) {
      logsContainerRef.current.scrollTop = Number.MAX_SAFE_INTEGER;
    }
  }, [command.logs]);

  const commanTypeLogo = useMemo(() => {
    switch (command.type) {
      case 'customcommand':
        return 'play';
      case 'vnctunnel':
        return 'mouse-pointer';
      case 'kill':
        return 'times';
      case 'reboot':
        return 'power-off';
      case 'screenshot':
        return 'desktop';
      case 'sshtunnel':
        return 'terminal';
      default:
        return 'unknown';
    }
  }, [command.type]);

  const commanTypeLogoModifier = useMemo(() => {
    switch (command.type) {
      case 'customcommand':
        return 'has-background-grey-lighter has-text-success';
      case 'vnctunnel':
        return 'is-primary';
      case 'kill':
        return 'has-background-grey-lighter has-text-danger';
      case 'reboot':
        return 'is-danger';
      case 'screenshot':
        return 'is-warning';
      case 'sshtunnel':
        return 'is-dark';
      default:
        return 'unknown';
    }
  }, [command.type]);

  return (
    <div
      className={`box has-background-${command.completed ? 'success-light' : 'info-light'}`}
      style={{ marginBottom: 5 }}
    >
      <div style={{ marginBottom: 8 }}>
        <ToggleNestedButton
          id={String(command.id)}
          hasChildren
          $expandedIds={useSelectorWithChangeTrigger(
            $deviceDetails.$expandedCommandIds,
            onExpandCollapseCommandCallback
          )}
        />
        <span className="is-family-code">
          {`${command.type !== 'customcommand' ? command.type : ''} ${command.arguments}`}
        </span>
        <div style={{ float: 'right' }}>
          {!command.completed && command.pid && (
            <ClickableIcon
              size="medium"
              id="times"
              iconColor="red"
              clickHandler={killCommandActionCallback}
            />
          )}
          {!command.completed && (
            <FaIcon
              id="sync"
              additionalClass={command.acknowledged && command.pid ? 'fa-spin' : ''}
              iconColor={command.pid ? 'green' : 'gray'}
            />
          )}
          {!command.acknowledged && <FaIcon id="clock" iconColor="gray" />}
          <span
            className={`tag ${
              !command.completed
                ? commanTypeLogoModifier
                : 'has-background-grey-light has-text-grey-lighter'
            } is-family-code`}
          >
            <FaIcon id={commanTypeLogo} />
          </span>
          {command.acknowledged && (
            <span className="tag is-family-code" style={{ ...codeTagStyle, minWidth: '60px' }}>
              {!command.pid ? '...' : command.pid}
            </span>
          )}
          <span
            className={`tag is-${!command.exitCode ? 'success' : 'warning'} is-family-code`}
            style={{ minWidth: '40px' }}
          >
            {!command.completed ? '...' : command.exitCode}
          </span>
        </div>
      </div>
      {commandIsExpanded && (
        <>
          {!command.completed && isTunneledCommand(command) && (
            <>
              <div className="columns m-t-none">
                <div className="column">
                  <ReadOnlyInputFormField
                    label="Manual:"
                    className="is-family-code is-info"
                    horizontal
                    style={{
                      backgroundColor: XTERM_RUNNING_BG_COLOR,
                      color: XTERM_TEXT_COLOR,
                    }}
                    value={manualConnectionString ?? ''}
                  />
                </div>
                <div className="column is-narrow p-l-none">
                  <Button
                    iconId="copy"
                    onClick={copyManualConnectionStringToClipboard}
                    size="small"
                    disabled={!command.tunnelPort}
                  />
                </div>
                <div className="column is-narrow p-l-none">
                  <Button
                    iconId="external-link-alt"
                    onClick={openVncOrSshViewerUrlCallback}
                    size="small"
                    disabled={!command.tunnelPort}
                  />
                </div>
              </div>
            </>
          )}
          <div
            ref={logsContainerRef}
            className="is-size-6"
            style={{
              ...codeTagStyle,
              borderColor: darkenColor(codeTagStyle.backgroundColor, 20),
              borderWidth: '0.125em',
              borderStyle: 'solid',
              borderRadius: 4,
              padding: 5,
              fontFamily: 'monospace',
              color: XTERM_TEXT_COLOR,
              lineHeight: '1.2em',
              overflowY: 'scroll',
              maxHeight: 200,
              minHeight: 100,
            }}
          >
            {sortedLogs.map((l) => (
              <span
                key={l.id}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: `${ansiConverter.ansi_to_html(l.data)}<br>` }}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}
