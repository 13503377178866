import type { JSX } from 'react';
import React, { useMemo } from 'react';
import type { UIMonitoredDevice } from './state/typings/store.js';

function toLogoId(system: string, model: string): string {
  if (system.toLocaleLowerCase().trim().startsWith('darwin')) {
    return 'apple';
  }
  if (model.toLocaleLowerCase().trim().startsWith('raspberry')) {
    return 'raspberrypi';
  }
  return 'linux';
}

export interface DeviceLogoProps {
  readonly device: UIMonitoredDevice;
  readonly size: number;
}

export function DeviceLogo({ device, size }: DeviceLogoProps): JSX.Element {
  const { system, model } = device;
  const src = useMemo(() => `/img/logos/${toLogoId(system, model)}.png`, [system, model]);
  return <img src={src} width={size} height={size} alt="device logo" />;
}
