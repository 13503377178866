import type { JSX } from 'react';
import React, { useEffect } from 'react';
import type { ActionContext, ActionDispatch } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import type { MonitoredDevice } from '@stimcar/monitor-libs-common';
import { isTruthyAndNotEmpty, Logger } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { BoundTableActionCell, FaIcon, TruncableTableTd } from '@stimcar/libs-uitoolkit';
import { MonitorBackendRoutes } from '@stimcar/monitor-libs-common';
import type { DeviceDetailsState, Store, StoreState } from './state/typings/store.js';
import { openRemoveDeviceDialogAction, selectDeviceAction } from './DeviceDetailsActions.js';
import { openEditDialogAction } from './EditDeviceDialog.js';

/* eslint-disable jsx-a11y/control-has-associated-label */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

export const approveDeviceAction = async (
  { httpClient }: ActionContext<Store, StoreState>,
  deviceShortId: string
): Promise<void> => {
  await httpClient.httpGet(MonitorBackendRoutes.APPROVE_DEVICE(deviceShortId));
};

// FIXME : this is not an correct action
// The function shouldn't take a dispatch as an argument and should instead
// take an ActionContext as an argument.
async function approveDeviceCellAction(
  dispatch: ActionDispatch<Store, StoreState>,
  device: MonitoredDevice
): Promise<void> {
  // FIXME : we shouldn't have to call another action. The function should be an action itself.
  await dispatch.exec(approveDeviceAction, device.shortId);
}

// FIXME : this is not an correct action
// The function shouldn't take a dispatch as an argument and should instead
// take an ActionContext as an argument.
async function openEditDeviceDialogCellAction(
  dispatch: ActionDispatch<Store, StoreState>,
  device: MonitoredDevice
): Promise<void> {
  // FIXME : we shouldn't have to call another action. The function should be an action itself.
  await dispatch.scopeProperty('editDeviceDialog').exec(openEditDialogAction, device);
}

// FIXME : this is not an correct action
// The function shouldn't take a dispatch as an argument and should instead
// take an ActionContext as an argument.
async function openRemoveSelectedDeviceDialogCellAction(
  dispatch: ActionDispatch<Store, StoreState>,
  device: MonitoredDevice
): Promise<void> {
  // FIXME : we shouldn't have to call another action. The function should be an action itself.
  await dispatch.exec(openRemoveDeviceDialogAction, device);
}

export interface ProtectUnconfirmedDeviceCellProps {
  readonly status: MonitoredDevice['status'];
  readonly children: JSX.Element;
}

interface UpdatedSinceCellProps {
  readonly since: number;
}

function UpdatedSinceCell({ since }: UpdatedSinceCellProps): JSX.Element {
  const [now, setNow] = React.useState(Date.now());
  const seconds = Math.max(1, (now - since) / 1000);
  const minutes = seconds > 60 ? seconds / 60 : 0;
  const hours = minutes > 60 ? minutes / 60 : 0;
  const days = hours > 24 ? hours / 24 : 0;
  const months = days > 30 ? days / 30 : 0;
  useEffect(() => {
    const interval = setInterval(() => {
      setNow(Date.now());
    }, 5000);
    return (): void => clearInterval(interval);
  }, []);
  return (
    <>
      {months ? `${months.toFixed(0)}mth` : ''}
      {!months && days ? `${days.toFixed(0)}d` : ''}
      {!days && hours ? `${hours.toFixed(0)}h` : ''}
      {!days && !hours && minutes ? `${minutes.toFixed(0)}m` : ''}
      {!days && !hours && !minutes && seconds ? `${seconds.toFixed(0)}s` : ''}
    </>
  );
}

interface IconSinceProps extends UpdatedSinceCellProps {
  readonly status: MonitoredDevice['status'];
  readonly tooltip: string;
}

function IconSince({ since, status, tooltip }: IconSinceProps): JSX.Element {
  const [now, setNow] = React.useState(Date.now());
  const seconds = Math.max(1, (now - since) / 1000);
  useEffect(() => {
    const interval = setInterval(() => {
      setNow(Date.now());
    }, 5000);
    return (): void => clearInterval(interval);
  }, []);
  return (
    <>
      {status === 'unconfirmed' && <FaIcon id="times-circle" iconColor="grey" />}
      {status === 'confirmed' && (
        <FaIcon
          id={seconds < 70 ? 'check-square' : 'question-circle'}
          iconColor={seconds < 70 ? 'green' : 'orange'}
          tooltip={tooltip}
        />
      )}
    </>
  );
}

interface PercentCellProps {
  readonly total: number;
  readonly usage: number;
}

const PercentCell = ({ total, usage }: PercentCellProps): JSX.Element => {
  const percent = (usage / total) * 100;
  return (
    <td
      className="has-text-right"
      style={{
        backgroundColor: `rgba(255, 44, 10, ${
          total === 0 || percent <= 50 ? 0 : (percent - 50) / 50
        })`,
      }}
    >
      {total !== 0 && <>{`${((usage / total) * 100).toFixed(0)}%`}</>}
    </td>
  );
};

export interface DeviceRowProps extends AppProps<Store> {
  readonly device: MonitoredDevice;
}

export function DeviceRow({ $gs, device }: DeviceRowProps): JSX.Element {
  const { $deviceDetails } = $gs;
  const selectedDeviceShortId = useGetState($deviceDetails.$selectedDeviceShortId);
  const selectDeviceActionCallback = useActionCallback(
    async ({ actionDispatch }: ActionContext<Store, DeviceDetailsState>): Promise<void> => {
      await actionDispatch.exec(selectDeviceAction, device.shortId);
    },
    [device.shortId],
    $deviceDetails
  );
  const isSelected = selectedDeviceShortId === device.shortId;
  const {
    id,
    shortId,
    label,
    location,
    lastConnection,
    publicIp,
    lanIp,
    model,
    status,
    rootDiskSpace,
    rootDiskUsage,
    homeDiskSpace,
    homeDiskUsage,
    memory,
    memoryUsage,
    systemLoad,
    system,
    systemArch,
    systemNode,
    systemRelease,
  } = device;
  return (
    <tr key={id} onClick={selectDeviceActionCallback} className={isSelected ? 'is-selected' : ''}>
      <td>
        <IconSince since={lastConnection} status={status} tooltip={id} />
      </td>
      <TruncableTableTd className="has-text-right">
        <UpdatedSinceCell since={lastConnection} />
      </TruncableTableTd>
      <TruncableTableTd>{shortId}</TruncableTableTd>
      <TruncableTableTd>{location}</TruncableTableTd>
      <TruncableTableTd>{isTruthyAndNotEmpty(label) ? label : <i>{id}</i>}</TruncableTableTd>
      <TruncableTableTd>{model}</TruncableTableTd>
      {!selectedDeviceShortId && (
        <>
          <td className="has-text-right">{publicIp}</td>
          <td className="has-text-right">{lanIp}</td>
          <td>{system}</td>
          <td>{systemRelease}</td>
          <td>{systemArch}</td>
          <td>{systemNode}</td>
          <td className="has-text-right">{systemLoad}</td>
          <td className="has-text-right">
            {memory !== 0 && <>{`${(memory / 1024 / 1024).toFixed(1)}G`}</>}
          </td>
          <PercentCell total={memory} usage={memoryUsage} />
          <td className="has-text-right">
            {rootDiskSpace !== 0 && <>{`${(rootDiskSpace / 1024).toFixed(1)}G`}</>}
          </td>
          <PercentCell total={rootDiskSpace} usage={rootDiskUsage} />
          <td className="has-text-right">
            {homeDiskSpace !== 0 && <>{`${(homeDiskSpace / 1024).toFixed(1)}G`}</>}
          </td>
          <PercentCell total={homeDiskSpace} usage={homeDiskUsage} />
          {status === 'confirmed' && (
            <BoundTableActionCell
              action={openEditDeviceDialogCellAction}
              iconId="edit"
              item={device}
              $={$gs}
              tooltip="Approve"
            />
          )}
          {status === 'unconfirmed' && (
            <BoundTableActionCell
              action={approveDeviceCellAction}
              iconId="check-square"
              item={device}
              $={$gs}
              tooltip="Approve"
            />
          )}
          <BoundTableActionCell
            action={openRemoveSelectedDeviceDialogCellAction}
            iconId="trash"
            item={device}
            $={$gs}
            tooltip="Remove"
          />
        </>
      )}
    </tr>
  );
}
