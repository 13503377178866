import type { HTTPClientWithSSE } from '@stimcar/libs-base';
import type { KeyValueStorage } from '@stimcar/libs-kernel';
import type {
  LightDarkThemeSupportStateContainer,
  StoreDef,
  WithFormValidationWarnings,
} from '@stimcar/libs-uikernel';
import type {
  BaseStoreActionContext,
  BaseStoreState,
  FormWithValidationState,
  TableContext,
} from '@stimcar/libs-uitoolkit';
import type {
  MonitoredDevice,
  MonitoredDeviceCommand,
  MonitoredDeviceCommandLog,
} from '@stimcar/monitor-libs-common';
import { EMPTY_MONITORED_DEVICE } from '@stimcar/monitor-libs-common';

export type CommandFormData = {
  readonly value: string;
};

export type CommandFormState = WithFormValidationWarnings<CommandFormData>;

export const EMPTY_COMMAND_FORM: CommandFormState = {
  value: '',
  warnings: {},
};

export type DeviceFormData = Pick<MonitoredDevice, 'label' | 'location'>;

export type DeviceForm = WithFormValidationWarnings<DeviceFormData>;

const EMPTY_DEVICE_FORM: DeviceForm = {
  label: '',
  location: '',
  warnings: {},
};

export type EditDeviceDialogState = FormWithValidationState<DeviceFormData> & {
  readonly active: boolean;
  readonly initialDevice: MonitoredDevice;
};

export const EMPTY_EDIT_DEVICE_DIALOG_STATE: EditDeviceDialogState = {
  active: false,
  formSubmitClickedOnce: false,
  formSubmitted: false,
  formWarning: undefined,
  initialDevice: EMPTY_MONITORED_DEVICE,
  formData: EMPTY_DEVICE_FORM,
};

export type ConfirmRemoveDeviceDialogState = {
  readonly active: boolean;
  readonly shortId: string;
};

export const EMPTY_CONFIRM_REMOVE_DEVICE_DIALOG_STATE: ConfirmRemoveDeviceDialogState = {
  active: false,
  shortId: '',
};

export type UIMonitoredDeviceCommand = MonitoredDeviceCommand & {
  readonly deviceShortId: string;
  readonly tunnelPort?: number;
  readonly autoOpenVncOrSshOrScreenshotViewer?: boolean;
  readonly logs: readonly MonitoredDeviceCommandLog[];
};

export type DeviceDetailsState = {
  readonly selectedDeviceShortId?: string;
  readonly commandForm: CommandFormState;
  readonly expandedCommandIds: readonly string[];
  readonly commands: readonly UIMonitoredDeviceCommand[];
  readonly confirmRebootModalDialogIsActive: boolean;
};

export const EMPTY_DEVICE_DETAILS_STATE: DeviceDetailsState = {
  commandForm: EMPTY_COMMAND_FORM,
  expandedCommandIds: [],
  commands: [],
  confirmRebootModalDialogIsActive: false,
};

export type UIMonitoredDevice = MonitoredDevice & {
  readonly screenshotUpdateTime?: number;
};

export const EMPTY_UI_MONITORED_DEVICE_STATE: UIMonitoredDevice = {
  ...EMPTY_MONITORED_DEVICE,
};

export type StoreState = BaseStoreState &
  LightDarkThemeSupportStateContainer & {
    readonly locationFilter: string;
    readonly devices: readonly UIMonitoredDevice[];
    readonly editDeviceDialog: EditDeviceDialogState;
    readonly confirmRemoveDeviceDialog: ConfirmRemoveDeviceDialogState;
    readonly deviceDetails: DeviceDetailsState;
  };

export interface StoreActionContext extends TableContext, BaseStoreActionContext {
  readonly httpClient: HTTPClientWithSSE;
  readonly keyValueStorage: KeyValueStorage;
}

export type Store = StoreDef<StoreState, StoreActionContext>;
