export function lightenOrDarkenColorComp(c: string, percent: number): string {
  const actualValue = parseInt(c, 16);
  const newValue = ((percent > 0 ? 255 - actualValue : actualValue) * percent) / 100 + actualValue;
  const result = Math.max(Math.min(Math.round(newValue), 255), 0)
    .toString(16)
    .padStart(2, '0');
  return result;
}

export function lightenColor(rrggbbHex: string, percent: number): string {
  return `#${lightenOrDarkenColorComp(
    rrggbbHex.substring(1, 3),
    percent
  )}${lightenOrDarkenColorComp(rrggbbHex.substring(3, 5), percent)}${lightenOrDarkenColorComp(
    rrggbbHex.substring(5, 7),
    percent
  )}`;
}

export function darkenColor(rrggbbHex: string, percent: number): string {
  return lightenColor(rrggbbHex, -percent);
}
