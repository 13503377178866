import { HTTPClientWithSSEImpl } from '@stimcar/libs-base';
import { BrowserEventSourceFactory, BrowserFormDataFactory } from '@stimcar/libs-uitoolkit';
import { MonitorBackendRoutes } from '@stimcar/monitor-libs-common';

export class MonitorHttpClientImpl extends HTTPClientWithSSEImpl {
  /**
   * Default constructor.
   */
  public constructor() {
    super(fetch.bind(window), BrowserFormDataFactory, BrowserEventSourceFactory);
    this.restartSSE(MonitorBackendRoutes.SSE);
  }
}
